// 下記のようにクラスを付与してください
// ・対象エリア : .js-image-or-alert
// ・画像 : .js-image-or-alert-image
// ・画像が表示エラーとなった場合に表示したい要素 : .js-image-or-alert-alert
// また、画像は遅延読み込みをするため、ファイルパスは src に設定せずに data-src に設定してください
document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-image-or-alert')) {
    const image = document.querySelector('.js-image-or-alert-image')
    image.onerror = () => {
      const alert = document.querySelector('.js-image-or-alert-alert')
      alert.classList.remove('d-none')
    }
    image.src = image.dataset.src
  }
})
