document.addEventListener('DOMContentLoaded', () => {
  const xyzElement = document.querySelector('[data-target="xyz"]')

  if (xyzElement) {
    const scrollAnimation = () => {
      // ======== 背景画像に視差効果を付ける
      const bgParallaxTarget = document.querySelector('[data-target="bg-parallax"]')
      bgParallaxTarget.style.backgroundPositionY = `${window.scrollY * -0.66}px`

      // ======== スクロールに合わせて要素を表示する
      const scrollInTargets = document.querySelectorAll('[data-target="scroll-in"]')
      scrollInTargets.forEach((scrollInTarget) => {
        const scrollOffSet = window.innerHeight * 0.25
        const rect = scrollInTarget.getBoundingClientRect().top
        const scroll = document.documentElement.scrollTop
        const offset = rect + scroll
        const windowHeight = window.innerHeight

        if (scroll > offset - windowHeight + scrollOffSet) {
          scrollInTarget.dataset.show = 'true'
        }
      })
    }
    document.addEventListener('scroll', scrollAnimation)
    document.addEventListener('resize', scrollAnimation)
    scrollAnimation()
  }
})
