document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-admin-copyright-information-form')) {
    const jsonElement = document.querySelector('#js-autofill-json')
    const data = JSON.parse(jsonElement.value)
    const autofillButton = document.querySelector('.js-autofill-button')
    const fieldsName = ['hososhibunken_holder', 'interactiveshibunken_holder', 'title', 'title_kana', 'artist', 'artist_kana', 'composer', 'composer_kana', 'composer_autonym', 'composer_autonym_kana']

    autofillButton.addEventListener('click', () => {
      if (confirm('著作権管理情報のデフォルト値を入力してもよいですか？')) {
        fieldsName.forEach((field) => {
          document.querySelector(`#copyright_information_${field}`).value = data[field]
        })
        alert('著作権管理情報のデフォルト値を入力しました。')
      }
    })
  }
})
