import React, { Component } from 'react'
import SideMenuCard from './SideMenuCard'

export default class SideMenu extends Component {
  constructor(props) {
    super(props)
    this.state = { searchText: '' }
  }
  handleInputSearchText = (e) => this.setState({ searchText: e.target.value })
  render() {
    const { searchText } = this.state
    const { menu } = this.props
    const targetMenu = menu.filter(({ permitted }) => permitted === 'true')
    return (
      <nav id="managementMenu" className="collapse d-md-block my-3">
        <div className="menu__search-group">
          <input
            type="text"
            className="form-control"
            placeholder="メニューを検索"
            onChange={this.handleInputSearchText}
          />
          <i className="fass fa-search fa-lg text-body-secondary" />
        </div>
        {targetMenu.map((menuItem) => (
          <SideMenuCard menu={menuItem} searchText={searchText} menuCount={targetMenu.length} key={menuItem.title} />
        ))}
      </nav>
    )
  }
}
