/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(function() {
  const mergeForm = $('.js-merge-form');
  if (1 < mergeForm.length) {
    mergeForm.submit(function(event) {
      event.preventDefault();
      const targetForm = $(event.target);
      const newForm = $('<form/>', {action: targetForm.attr('action'), method: targetForm.attr('method')});
      $('.js-merge-form :input').not(':submit').clone().hide().appendTo(newForm);
      $('select', mergeForm).each(function(i) { return $('select', newForm).eq(i).val($(this).val()); });
      newForm.appendTo(document.body);
      newForm.submit();
      return newForm.remove();
    });
  }

  const tempoRadioButton = $('input[name$="audio_search[tempo]"]');
  return tempoRadioButton.change(function(event) {
    if (parseInt($(this).val()) === 7) {
      $('[id$=audio-search-bpm]').removeClass('d-none');
      $('[id$=audio_search_bpm_min]').prop('disabled', false);
      return $('[id$=audio_search_bpm_max]').prop('disabled', false);
    } else {
      $('[id$=audio-search-bpm]').addClass('d-none');
      $('[id$=audio_search_bpm_min]').prop('disabled', true);
      return $('[id$=audio_search_bpm_max]').prop('disabled', true);
    }
  });
});
