/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(function() {
  let check_str_element = document.getElementById('inquiry_check_str');
  if (check_str_element !== null) {
    check_str_element.value = 'Audiostock';
  }

  check_str_element = $('.js-inquiry-check-str');
  if (check_str_element.is('*')) {
    return check_str_element.each(function() {
      return $(this).val('Audiostock');
    });
  }
});
