$(() => {
  const jsAdminAudioSpecialPackageClassName = 'js-admin-audio-special-package'
  const $jsAdminAudioSpecialPackage = $('.' + jsAdminAudioSpecialPackageClassName)
  const $adminAudioSpecialPackageUserUploadedFileName = $('#user_uploaded_file_name')

  const validationError = (alertMessage) => {
    alert(alertMessage)
    $('button[type=submit]', $jsAdminAudioSpecialPackage).removeAttr('data-disable-with')
  }

  if ($jsAdminAudioSpecialPackage.is('*')) {
    $(document).on('submit', $jsAdminAudioSpecialPackage, (event) => {
      if($adminAudioSpecialPackageUserUploadedFileName.val()) {
        return;
      }

      const eventTargetClassName = $(event.target).attr('class')
      if(!eventTargetClassName || eventTargetClassName.indexOf(jsAdminAudioSpecialPackageClassName) == -1) {
        return;
      }
      event.preventDefault();
      const files = document.getElementById('special_package_zip_file').files;
      if(files.length === 0){
        validationError('ファイルを選択してください');
        return false;
      }

      window.showLoading();

      const file = files[0];
      const url = $jsAdminAudioSpecialPackage.data('get-presigned-url')
      const formdata = new FormData()
      formdata.append("authenticity_token", $('meta[name="csrf-token"]').attr('content'));
      formdata.append("filename", encodeURIComponent(file.name));
      formdata.append("audio_id", document.getElementById('special_package_audio_id').value);

      fetch(
        url,
        {
          body: formdata,
          method: 'POST'
        }
      ).then((response) => {
        if(response.ok){
          return response.json();
        }
      }).then((data) => {
        const formdata = new FormData()
        for (const key in data.fields) {
          formdata.append(key,data.fields[key]);
        }
        formdata.append("file", file);
        const headers = {
          "accept": "multipart/form-data"
        }
        // S3 に POST
        fetch(
          data.url,
          {
            method: 'POST',
            headers,
            body: formdata
          }
        ).then((response) => {
          if(response.ok){
            $adminAudioSpecialPackageUserUploadedFileName.val(data.file_name);

            $jsAdminAudioSpecialPackage.submit();
          }else{
            alert('ファイルのアップロードに失敗しました')
          }
        })
      })
    })
  }
})
