$(function() {
  $('.js-subscription-download').on('click', function() {
    var audioId = $(this).data('audio-id');
    var $button = $('.js-audio-subscription-button-' + audioId)
    if ($button.hasClass('player-audio-btn-subscription')) {
      $button.removeClass('player-audio-btn-subscription').addClass('player-audio-btn-subscription-downloaded');
    }
    if ($button.hasClass('btn-outline-orange-lg')) {
      $button.removeClass('btn-outline-orange-lg').addClass('btn-orange-lg');
    }
  });
});
