document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-s-plan-payment')) {
    // -------- 支払い直前の契約情報ブロックの「お支払い金額」「支払い周期」部分の表示制御を行う
    const paymentCycleYearlyRadios = document.querySelectorAll('.js-payment-cycle-yearly-radio')
    const paymentCycleMonthlyRadios = document.querySelectorAll('.js-payment-cycle-monthly-radio')
    const hideByClassName = (className) => {
      document.querySelectorAll(className).forEach(hideElement)
    }

    if (paymentCycleYearlyRadios.length && paymentCycleMonthlyRadios.length) {
      // 初期状態は「選択してください」表示にする
      hideByClassName('.js-payment-cycle-yearly')
      hideByClassName('.js-payment-cycle-monthly')

      // 年間一括払いが押下された場合
      paymentCycleYearlyRadios.forEach((paymentCycleYearlyRadio) => {
        paymentCycleYearlyRadio.addEventListener('change', (e) => {
          const form = e.target.closest('form')
          form.querySelectorAll('.js-payment-cycle-blank').forEach(hideElement)
          form.querySelectorAll('.js-payment-cycle-yearly').forEach(showElement)
          form.querySelectorAll('.js-payment-cycle-monthly').forEach(hideElement)
        })
      })

      // 月々分割払いが押下された場合
      paymentCycleMonthlyRadios.forEach((paymentCycleMonthlyRadio) => {
        paymentCycleMonthlyRadio.addEventListener('change', (e) => {
          const form = e.target.closest('form')
          form.querySelectorAll('.js-payment-cycle-blank').forEach(hideElement)
          form.querySelectorAll('.js-payment-cycle-yearly').forEach(hideElement)
          form.querySelectorAll('.js-payment-cycle-monthly').forEach(showElement)
        })
      })
    } else {
      // 支払い周期の選択肢がなければ年額表記のみにする
      hideByClassName('.js-payment-cycle-blank')
      hideByClassName('.js-payment-cycle-monthly')
    }


    // -------- 支払い直前の契約情報ブロックの「お支払い方法」部分の表示制御を行う
    const paymentWayEmailRadios = document.querySelectorAll('.js-payment-way-email-radio')
    const paymentWayPostRadios = document.querySelectorAll('.js-payment-way-post-radio')

    if (paymentWayEmailRadios.length && paymentWayPostRadios.length) {
      // 初期状態は「選択してください」表示にする
      hideByClassName('.js-payment-way-email')
      hideByClassName('.js-payment-way-post')

      // 請求書支払い（メール）が押下された場合
      paymentWayEmailRadios.forEach((paymentWayEmailRadio) => {
        paymentWayEmailRadio.addEventListener('change', (e) => {
          const form = e.target.closest('form')
          form.querySelectorAll('.js-payment-way-blank').forEach(hideElement)
          form.querySelectorAll('.js-payment-way-email').forEach(showElement)
          form.querySelectorAll('.js-payment-way-post').forEach(hideElement)
        })
      })

      // 請求書支払い（郵送）が押下された場合
      paymentWayPostRadios.forEach((paymentWayPostRadio) => {
        paymentWayPostRadio.addEventListener('change', (e) => {
          const form = e.target.closest('form')
          form.querySelectorAll('.js-payment-way-blank').forEach(hideElement)
          form.querySelectorAll('.js-payment-way-email').forEach(hideElement)
          form.querySelectorAll('.js-payment-way-post').forEach(showElement)
        })
      })
    }

    function hideElement(element) {
      element.classList.add('d-none')
    }

    function showElement(element) {
      element.classList.remove('d-none')
    }
  }
})
