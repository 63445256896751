export const getCookie = ({key}) => {
  const targetKeyValueSet = document.cookie.split('; ').find((row) => row.startsWith(key))
  if (targetKeyValueSet) return decodeURI(targetKeyValueSet.split('=')[1])
}

// デフォルトは有効期限2年間
export const setCookie = ({key, value, expires = 1000 * 60 * 60 * 24 * 365 * 2, path = '/', sameSite = 'Lax'}) => {
  const date = new Date()
  date.setTime(date.getTime() + expires)
  document.cookie = [`${encodeURIComponent(key)}=${encodeURIComponent(value)};`,
    `${encodeURIComponent('expires')}=${date.toUTCString()};`,
    `${encodeURIComponent('path')}=${encodeURIComponent(path)};`,
    `${encodeURIComponent('secure')};`,
    `${encodeURIComponent('SameSite')}=${encodeURIComponent(sameSite)}`].join('')
}