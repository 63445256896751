/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(function() {
  $('body').on('keyup', '.js-audio-url-input', function(e) {
    return $('.js-audio-url-output').html("<iframe width='100%' height='100' scrolling='no' frameborder='no' src='" + encodeURI($(this).data('root-url')) + "embed?id=" + encodeURIComponent($(this).val()) + "'></iframe>");
  });


  return $('body').on('keyup', '.js-youtube-url-input', function(e) {
    const id = $(this).val().replace("https://www.youtube.com/watch?v=", '').replace("https://youtu.be/", '');
    $('.js-youtube-url-output').html("<iframe frameborder='0' src='//www.youtube.com/embed/" + encodeURIComponent(id) + "'></iframe>");

    return $.ajax({
      type : 'get',
      url : '/admin_creator/youtube_content_reports/get_youtube_content',
      data : {
        id
      },
      success(res) {
        if ($('.js-youtube-title').is('*') && ($('.js-youtube-title').val().length === 0)) {
          $('.js-youtube-title').val(res["title"]);
        }
        if ($('.js-youtube-channel-title').is('*') && ($('.js-youtube-channel-title').val().length === 0)) {
          return $('.js-youtube-channel-title').val(res["channel_title"]);
        }
      }
    });
  });
});
