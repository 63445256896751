// スクロール時にボタンを下部に固定する場合に使用します。
// .js-scroll-fix-btn-area ... 固定するボタンのラッパーに付けてください
// .js-scroll-fix-start-position ... スクロールしてこの要素が画面から消えたらボタンが固定されます

document.addEventListener('DOMContentLoaded', () => {
  const scrollFixBtnArea = document.querySelector('.js-scroll-fix-btn-area')
  const scrollFixStartPosition = document.querySelector('.js-scroll-fix-start-position')
  const scrollFixBtnStoppedClass = 'stopped'

  if (scrollFixBtnArea && scrollFixStartPosition) {
    let startFixedPosition
    let endFixedPosition

    const getOffsetTop = (element) => (window.pageYOffset || document.documentElement.scrollTop) + element.getBoundingClientRect().top
    const setFixedPositions = () => {
      startFixedPosition = getOffsetTop(scrollFixStartPosition) + window.innerHeight
      endFixedPosition = getOffsetTop(scrollFixBtnArea.parentNode) + scrollFixBtnArea.offsetHeight
    }

    scrollFixBtnArea.style.transition = 'bottom .6s ease-in-out'
    scrollFixBtnArea.style.bottom = `${-scrollFixBtnArea.offsetHeight}px`
    scrollFixBtnArea.style.left = 0

    const handleScroll = () => {
      setFixedPositions()

      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop
      const currentPosition = scrollPosition + window.innerHeight
      startFixedPosition = getOffsetTop(scrollFixStartPosition) + document.documentElement.clientHeight
      endFixedPosition = getOffsetTop(scrollFixBtnArea.parentNode) + scrollFixBtnArea.offsetHeight

      if (currentPosition < startFixedPosition) {
        scrollFixBtnArea.classList.remove(scrollFixBtnStoppedClass)
        scrollFixBtnArea.style.position = 'fixed'
        scrollFixBtnArea.style.zIndex = 10
        scrollFixBtnArea.style.bottom = `${-scrollFixBtnArea.offsetHeight}px`
      } else if (currentPosition < endFixedPosition) {
        if (currentPosition < endFixedPosition - scrollFixBtnArea.offsetHeight) {
          scrollFixBtnArea.classList.remove(scrollFixBtnStoppedClass)
          scrollFixBtnArea.style.position = 'fixed'
          scrollFixBtnArea.style.zIndex = 10
          scrollFixBtnArea.style.bottom = 0
        }
      } else {
        scrollFixBtnArea.classList.add(scrollFixBtnStoppedClass)
        scrollFixBtnArea.style.position = 'static'
        scrollFixBtnArea.style.bottom = `${-scrollFixBtnArea.offsetHeight}px`
      }
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll()
  }
})
