$(() => {
  const $parentForm = $('.js-select-keywords')

  if ($parentForm.is('*')) {
    const $keywordButtons = $('.js-select-keywords_keyword-item')
    const $selectedKeywordsList = $('.js-select-keywords_selected-item-list')
    const $keywordInputField = $('.js-select-keywords_input-item')
    const $keywordAppendButton = $('.js-select-keywords_append-item')
    const $limitCount = $('.js-select-keywords_keyword-limit')
    const keywordLimitCount = $parentForm.data('keyword-limit')
    const objectName = $parentForm.data('object-name')
    const columnName = $parentForm.data('column-name')

    // 選択中のキーワードのテキスト配列
    const selectedKeywords = () => $selectedKeywordsList.find('li').map((_, keyword) => $(keyword).data('keyword'))

    // タグ選択ボタン押下時の制御
    $(document).on('click', '.js-select-keywords .js-select-keywords_keyword-item', (e) => {
      const keyword = $(e.target).closest('button').val()
      const $keywordButton = $(`button[value='${keyword}']`)
      if ($keywordButton.attr('aria-selected') === 'true') {
        const keyword_li = $(`li[data-keyword='${keyword}']`)
        removeKeyword(keyword_li, selectedKeywords(), $keywordButton)
      } else {
        addKeyword(keyword, selectedKeywords(), $keywordButton)
      }
    })

    // タグの×ボタン押下時の制御
    $(document).on('click', '.js-select-keywords .js-select-keywords_selected-item-list li button.btn-close', (e) => {
      const $closeButton = $(e.target)
      const keyword = $closeButton.parents('li').data('keyword')
      const $keywordButton = $(`button[value='${keyword}']`)
      removeKeyword($closeButton.parents('li'), selectedKeywords(), $keywordButton)
    })

    // タグ追加ボタン押下時の制御
    $keywordAppendButton.on('click', () => {
      const newKeywords = $keywordInputField.val().trim()
      if (!(newKeywords.length > 0)) return false

      const newKeywordsArray = newKeywords.split('\n')
      if (newKeywordsArray.filter((keyword) => keyword.length > 50).length > 0) {
        alert('長すぎるタグがあります。1つのタグには最大50文字まで入力可能です。')
        return false
      }

      newKeywordsArray.forEach((newKeyword) => {
        const trimmedNewKeyword = newKeyword.trim()
        if (trimmedNewKeyword.length > 0) {
          if ($.inArray(trimmedNewKeyword, selectedKeywords()) < 0) {
            const $keywordButton = $(`button[value='${trimmedNewKeyword.replace(/'/g, "\\\\'")}']`)
            addKeyword(trimmedNewKeyword, selectedKeywords(), $keywordButton)
          }
        }
      })
      $keywordInputField.val('')
    })

    // Enterキー押下時の制御
    $keywordInputField.on('keypress', (e) => {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault()
        $keywordAppendButton.click()
      }
    })

    // タグのコピペ時に改行区切りに調整
    $selectedKeywordsList.on('copy', (e) => {
      e.preventDefault()

      const selection = window.getSelection()
      const dom = selection.getRangeAt(0).cloneContents()
      const items = dom.querySelectorAll('li')
      const keywordsArray = Array.from(items).map((item) => item.getAttribute('data-keyword'))
      const copyText = keywordsArray.join('\n')

      const userAgent = window.navigator.userAgent.toLowerCase()
      if (userAgent.match(/msie|trident/)) {
        window.clipboardData.setData('Text', copyText)
      } else {
        e.originalEvent.clipboardData.setData('text/plain', copyText)
      }
    })

    // キーワードを追加
    const addKeyword = (keyword, selectedKeywords, $keywordButton) => {
      if ($.inArray(keyword, selectedKeywords) > -1 || $limitCount.text() <= 0) return false

      $selectedKeywordsList.append(generateKeywordElement(keyword))
      $selectedKeywordsList.sortable('refresh')
      refreshKeywordLimit(selectedKeywords.length + 1)
      if ($keywordButton) changeKeywordSelected($keywordButton)
    }
    const generateKeywordElement = (keyword) => {
      const li = document.createElement('li')
      li.setAttribute('data-keyword', keyword)

      const div = document.createElement('div')
      div.setAttribute('class', 'btn tag-normal-selected tag-normal-with-close')
      div.textContent = keyword
      li.appendChild(div)

      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', `${objectName}[${columnName}][]`)
      input.setAttribute('value', keyword)
      li.appendChild(input)

      const button = document.createElement('button')
      button.setAttribute('class', 'btn-close')
      button.setAttribute('type', 'button')
      li.appendChild(button)

      return li
    }

    // キーワードを削除
    const removeKeyword = (keyword_li, selectedKeywords, $keywordButton) => {
      keyword_li.remove()
      refreshKeywordLimit(selectedKeywords.length - 1)
      if ($keywordButton) changeKeywordUnselected($keywordButton)
    }

    // ボタン表示を選択している状態に変更
    const changeKeywordSelected = (keywordButton) => {
      keywordButton.attr('aria-selected', 'true')
      keywordButton.removeClass('btn-outline-info')
      keywordButton.addClass('btn-info')
    }

    // ボタン表示を選択していない状態に変更
    const changeKeywordUnselected = (keywordButton) => {
      keywordButton.attr('aria-selected', 'false')
      keywordButton.removeClass('btn-info')
      keywordButton.addClass('btn-outline-info')
    }

    // 入力可能な残りキーワード数を再計算
    const refreshKeywordLimit = (keywordsCount) => {
      $limitCount.text(keywordLimitCount - keywordsCount)
      if (keywordLimitCount - keywordsCount > 0) {
        $limitCount.parent().show()
        $limitCount.parent().next('.text-danger').hide()
      } else {
        $limitCount.parent().hide()
        $limitCount.parent().next('.text-danger').show()
      }
    }

    // 初期化
    $selectedKeywordsList.sortable()
    refreshKeywordLimit(selectedKeywords().length)
    if (selectedKeywords().length !== 0) {
      selectedKeywords().each((_, keyword) => {
        $keywordButtons.each((__, keywordButton) => {
          if (keywordButton.innerText === keyword) {
            changeKeywordSelected($(keywordButton))
          }
        })
      })
    }
  }
})
