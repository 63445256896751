import React from 'react'

const SelectedAudio = ({ selectedAudio, selectedAudioId }) => (
  <div className="d-flex justify-content-between align-items-center">
    <p className="text-truncate fw-bold mb-0">
      <small className="text-body-secondary fw-bold me-1">No. {selectedAudioId}</small>
      {selectedAudio.name}
    </p>
    <i className="fass fa-sort" />
  </div>
)

export default SelectedAudio
