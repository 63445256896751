/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
(function() {
  let countUpCartIcon = undefined;
  const Cls = (window.AddCartCommon = class AddCartCommon {
    static initClass() {

      countUpCartIcon = function() {
        const cartCountBadge = document.getElementById('cart-count-badge');
        cartCountBadge.removeAttribute('hidden');
        const count = parseInt(cartCountBadge.innerText) + 1;
        return cartCountBadge.innerText = count;
      };
    }
    static addCart(audioId,changeBtn){
      return $.ajax({
        type: 'POST',
        url: '/audio/add_cart',
        data: {
          authenticity_token: $('meta[name="csrf-token"]').attr('content'),
          id: audioId
        },
        dataType: 'html',
        success(res){
          if (res !== "success") {
            return alert(res);
          } else {
            if (changeBtn) {
              changeBtn(audioId);
            }

            if ($('#cart-popup')[0]) {
              countUpCartIcon();
              return $('#cart-popup').modal('show');
            } else {
              return location.reload();
            }
          }
        }
      });
    }
  });
  Cls.initClass();
  return Cls;
})();
