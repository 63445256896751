$(function() {
  if ($('.js-music-distribution-request').is('*')) {
    // ISRC入力フォームの切替
    var updateIsrcField = function() {
      var $isrcField = $('.js-isrc-field');
      if ($isrcField.is('*')) {
        var isInputByAdmin = $('input[name*=isrc_inputter]:checked').val() === 'admin'
        $isrcField.prop('disabled', isInputByAdmin);
      }
    };
    $(document).on('change', 'input[name*=isrc_inputter]', updateIsrcField);
    updateIsrcField();

    // ジャンル選択
    var setGenre = function() {
      var number = $(this).data('number');
      var label = $(this).data('label');
      var $selectedGenre = $('.js-selected-genre-' + number);

      $selectedGenre.html(label).addClass('fw-bold');

      $('[class*=js-deselect-genre-' + number + ']').removeClass('d-none');
      $('.js-destroy-genre-' + number).prop('checked', false);
    };

    var resetGenre = function(number) {
      $('.js-genres-' + number).prop('checked', false);
      $('.js-selected-genre-' + number).html('なし').removeClass('fw-bold');
      $('.js-deselect-genre-' + number).addClass('d-none');
      $('.js-destroy-genre-' + number).prop('checked', true);
    };

    $(document).on('change', '[class*=js-genres-]', setGenre);
    $(document).on('click', '[class*=js-deselect-genre-]', function() {
      var number = $(this).data('number');
      resetGenre(number);
    });

    // ジャンル選択後のEnterでsubmitされるのを防止
    $(document).on('keypress', '[class*=js-genres-]', function(e) {
      var keyCode = (e.keyCode ? e.keyCode : e.which);
      if (keyCode === 13) {
        $(this).parents('.modal').modal('hide');
        e.preventDefault();
        return false
      }
    });

    // 初期化 (外部からも呼び出すため window に定義)
    window.initializeMusicDistributionGenre = function() {
      $('[class*=js-genres-]:checked').each(setGenre);

      var $noSelectedGenre = $('[class*=js-selected-genre-]:contains("なし")');
      $noSelectedGenre.each(function() {
        var number = $(this).data('number');
        resetGenre(number);
      });
    };
    initializeMusicDistributionGenre();
  }
});
