document.addEventListener('DOMContentLoaded', () => {
  const controlYoutube = (youtubePlayer, action) => {
    const playerWindow = youtubePlayer.contentWindow
    playerWindow.postMessage(`{ "event": "command", "func": "${encodeURIComponent(action)}", "args": "" }`, '*')
  }

  // modal 内の iframe の URL を src ではなく data-src にセットし、
  // class に .js-lazy-modal-youtube を設定することで、
  // モーダルが開いた時点で iframe の動画を遅延読み込みする
  const lazyYoutubeIframes = document.querySelectorAll('iframe.js-lazy-modal-youtube')

  if (lazyYoutubeIframes.length > 0) {
    lazyYoutubeIframes.forEach((iframe) => {
      iframe.style.backgroundColor = '#000'

      const src = iframe.getAttribute('data-src')
      const modal = iframe.closest('.modal')

      if (src && modal) {
        $(modal).on('show.bs.modal', () => {
          // src がなければモーダルを開くと同時にセット
          if (!iframe.getAttribute('src')) {
            iframe.setAttribute('src', src)
          }

          // すべての動画の再生を停止
          const youtubePlayers = document.querySelectorAll('iframe')
          youtubePlayers.forEach((youtubePlayer) => {
            controlYoutube(youtubePlayer, 'pauseVideo')
          })

          // モーダル内の動画を再生
          controlYoutube(iframe, 'playVideo')
          iframe.onload = () => controlYoutube(iframe, 'playVideo')
        })
      }
    })
  }

  // iframe の class に.js-autostop-youtube-modal を設定することで、
  // モーダルを閉じた時に自動的に動画の再生を停止する
  const autoStopYoutubeModal = document.querySelectorAll('.js-autostop-youtube-modal')
  autoStopYoutubeModal.forEach((modal) => {
    const autostopYoutubePlayers = modal.querySelectorAll('iframe.js-autostop-youtube')

    // 動画を含んだモーダル閉じると自動的に動画の再生を停止
    if (autostopYoutubePlayers.length) {
      $(modal).on('hidden.bs.modal', () => {
        autostopYoutubePlayers.forEach((youtubePlayer) => {
          controlYoutube(youtubePlayer, 'pauseVideo')
        })
      })
    }
  })

  // MEMO: Bootstrap 4 だと jQuery を使用しないとイベントをハンドルできない
  // Bootstrap 5 にバージョンアップしたら addEventListener で書き直したい
})
