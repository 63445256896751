$(() => {
  const $jsUrlForConfirm = $('.js-url-for-confirm');
  if ($jsUrlForConfirm.is('*')) {
    var showUrl = function(urlConfirm, urlInput){
      const url = $(urlInput).val();
      if(url.match(/https?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .?%&=]*)?/)) {
        $(urlConfirm).visibility = "visible";
        $(urlConfirm).attr('href', url);
        $(urlConfirm).text(url);
      } else {
        $(urlConfirm).visibility = "hidden";
        $(urlConfirm).attr('href', '');
        $(urlConfirm).text('');
      }
    }

    $jsUrlForConfirm.each(
      function(){
        const urlConfirm = this;
        const urlInput = $(this).parent().find('input[type=url]');
        $(urlInput).on('keydown keyup keypress change', function () { showUrl(urlConfirm, urlInput) });
        showUrl(urlConfirm, urlInput);
      }
    )
  }
})
