document.addEventListener('DOMContentLoaded', () => {
  const targetPage = document.querySelector('.js-as-15th')

  if (targetPage) {
    // ======== フェードイン要素の制御
    const scrollFadeIn = () => {
      document.querySelectorAll('.fade-in:not(.scroll-in)').forEach((fadeInTarget) => {
        const scrollOffSet = window.innerHeight * 0.06
        const rect = fadeInTarget.getBoundingClientRect().top
        const scroll = document.documentElement.scrollTop
        const offset = rect + scroll
        const windowHeight = window.innerHeight

        if (scroll > offset - windowHeight + scrollOffSet) {
          fadeInTarget.classList.add('scroll-in')
        }
      })
    }

    window.addEventListener('scroll', scrollFadeIn)
    window.addEventListener('resize', scrollFadeIn)
    scrollFadeIn()


    // ======== アイキャッチのフェードイン要素の制御
    // ページロード時に一定以上スクロールしている場合はフェードしない
    const scroll = document.documentElement.scrollTop
    const baseTargetHeight = window.innerWidth * .4
    const targetHeight = 320 > baseTargetHeight ? 320 : baseTargetHeight
    if (scroll > targetHeight) {
      document.querySelectorAll('.js-eye-catch-fade-in').forEach((fadeInTarget) => {
        fadeInTarget.classList.add('js-disable-fade-in')
      })
    }

    // ======== 残り時間カウンターの制御
    const day_1 = document.querySelector('.js-as-15th-day-1')
    const day_2 = document.querySelector('.js-as-15th-day-2')
    const hour_1 = document.querySelector('.js-as-15th-hour-1')
    const hour_2 = document.querySelector('.js-as-15th-hour-2')
    const minute_1 = document.querySelector('.js-as-15th-minute-1')
    const minute_2 = document.querySelector('.js-as-15th-minute-2')
    const second_1 = document.querySelector('.js-as-15th-second-1')
    const second_2 = document.querySelector('.js-as-15th-second-2')
    const targetDate = new Date('2022/11/1 00:00:00')

    const countDown = () => {
      const now = new Date()
      const diffTime = targetDate.getTime() > now.getTime() ? targetDate.getTime() - now.getTime() : 0

      const dayCount    = Math.floor(diffTime / 1000 / 60 / 60 / 24)
      const hourCount   = Math.floor(diffTime / 1000 / 60 / 60) % 24
      const minuteCount = Math.floor(diffTime / 1000 / 60) % 60
      const secondCount = Math.floor(diffTime / 1000) % 60

      day_1.innerHTML    = String(Math.floor(dayCount / 10))
      day_2.innerHTML    = String(dayCount % 10)
      hour_1.innerHTML   = String(Math.floor(hourCount / 10))
      hour_2.innerHTML   = String(hourCount % 10)
      minute_1.innerHTML = String(Math.floor(minuteCount / 10))
      minute_2.innerHTML = String(minuteCount % 10)
      second_1.innerHTML = String(Math.floor(secondCount / 10))
      second_2.innerHTML = String(secondCount % 10)
    }

    countDown()
    setInterval(countDown, 1000)
  }
})
