import { setCookie } from './cookie_utils'

/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//#######################################################################################################################
//
//
// Player class (static only)
//
//
//#######################################################################################################################
class Player {
  static initialize() {
    //#####################################################################################################################
    //
    // static methods and properties
    //
    //#####################################################################################################################
    //#####################################################################################################################
    // static properties
    //#####################################################################################################################
    this.TRANSPORT_SECONDS = 3;
    this.SELECTED_ITEM_HIGHLIGHT_CLASS = 'player-audio-inner-playing';

    this.audio = null;
    this.visible = null;
    this.mobile = false;
    this.controlExists = true;
    this.audioLoadError = false;
    this.forrowSelectedItem = true;

    this.volumeCache = 0;

    this.firstLoaded = false;
    this.loading = false;
    this.dragging = false;
    this.volumeDragging = false;
    this.volumeHovering = false;
    this.focused = false;

    this.playerControls = null;

    this.buttonVolume = null;
    this.buttonVolumeMute = null;
    this.buttonVolumeLow = null;
    this.buttonVolumeHigh = null;
    this.buttonVolumeMax = null;
    this.buttonVolumeControls = null;
    this.buttonVolumeTrack = null;
    this.buttonVolumeGauge = null;

    this.buttonPrev = null;
    this.buttonPlay = null;
    this.buttonNext = null;
    this.buttonPlayPlay = null;
    this.buttonPlayPause = null;
    this.buttonManualPopup = null;

    this.playerErrorMessage = null;

    this.playerItems = [];
    this.selectedPlayerItemIndex = null;
    this.selectedPlayerItem = null;
    this.currentTimePercentageBuffer = null;

    this.enablePlaySyncOpenManual = null;

    this.loadRetried = false

    $(".player-item").each(function() {
      if (!Player.visible) { // 一回だけ実行する
        Player.visible = true;
        Player.playerControls = $(".player-controls");
        Player.document = $(document);
        Player.audio = new Audio();

        if (Player.playerControls.length === 0) {
          Player.controlExists = false;
          Player.mobile = true;
          Player.forrowSelectedItem = false;
        } else {
          Player.mobile = false;
        }

        Player.buttonVolume = $(".volume", Player.playerControls);
        Player.buttonVolumeMute = $(".mute_image", Player.buttonVolume);
        Player.buttonVolumeLow = $(".low_image", Player.buttonVolume);
        Player.buttonVolumeHigh = $(".high_image", Player.buttonVolume);
        Player.buttonVolumeMax = $(".max_image", Player.buttonVolume);
        Player.buttonVolumeControls = $(".volume_controls", Player.playerControls);
        Player.buttonVolumeTrack = $(".volume_track", Player.buttonVolumeControls);
        Player.buttonVolumeGauge = $(".volume_gauge", Player.buttonVolumeTrack);
        Player.buttonPrev = $(".prev", Player.playerControls);
        Player.buttonPlay = $(".play", Player.playerControls);
        Player.buttonNext = $(".next", Player.playerControls);
        Player.buttonPlayPlay = $(".play_image", Player.buttonPlay);
        Player.buttonPlayPause = $(".pause_image", Player.buttonPlay);
        Player.playerErrorMessage = $(".player-error-message");
        Player.buttonManualPopup = $(".manual-popup", Player.playerControls);
        Player.enablePlaySyncOpenManual = $(".manual-popup", Player.playerControls).attr('data-enable-play-sync-open-manual') === 'true';
        Player.setPrevHandler();
        Player.setPlayHandler();
        Player.setNextHandler();
        Player.setVolumeHandler();
        Player.setVolumeMuteHandler();
        Player.setShortcutKeyHandler();
        Player.setManualPopupHandler();

        new Slider({
          target: Player.buttonVolumeTrack,
          mouseDownHandler: Player.volumeMouseDownHandler,
          mouseMoveHandler: Player.volumeMouseMoveHandler,
          mouseUpHandler: Player.volumeMouseUpHandler
        });

        $(window).resize(() => Array.from(Player.playerItems).map((playerItem) => // forEach使うとレガシIEが死ぬ
          playerItem.syncWaveformPlayedImageSize()));
      }

      return Player.playerItems.push(new PlayerItem($(this)));
    });

    if (Player.visible) {
      Player.initializeAudio();
      Player.setSelectedPlayerItemIndex(0);
      return Player.playerControls.show();
    }
  }

  static initializeVolume() {
    return Player.setVolume(Player.controlExists ? Player.playerControls.data("volume") : 1);
  }

  //#####################################################################################################################
  // player controls handlers
  //#####################################################################################################################
  static setPrevHandler() {
    return Player.buttonPrev.click(() => Player.prev());
  }

  static setPlayHandler() {
    return Player.buttonPlay.click(() => Player.selectedPlayerItem.playOrPause());
  }

  static setNextHandler() {
    return Player.buttonNext.click(() => Player.next());
  }

  static setVolumeHandler() {
    return Player.buttonVolume.hover(
      function() {
        Player.volumeHovering = true;
        return Player.buttonVolumeControls.stop(true).slideDown("fast");
      }
      ,
      function() {
        Player.volumeHovering = false;
        if (!Player.volumeDragging) {
          return Player.buttonVolumeControls.stop(true).slideUp("fast");
        }
    });
  }

  static setVolumeMuteHandler() {
    Player.buttonVolumeMute.click(() => Player.clearMute());
    Player.buttonVolumeLow.click(() => Player.mute());
    Player.buttonVolumeHigh.click(() => Player.mute());
    return Player.buttonVolumeMax.click(() => Player.mute());
  }

  static setManualPopupHandler() {
    return Player.buttonManualPopup.click(() => Player.manualPopupOpen());
  }

  static addSelectedIndexEventHandler(index, handler) {
    return Player.playerItems[index].addSelectedEventHandler(handler);
  }

  //#####################################################################################################################
  // player functions
  //#####################################################################################################################
  static setSelectedCommonBefore() {
    if (Player.firstLoaded) {
      Player.selectedPlayerItem.hideProgressForce();
      Player.selectedPlayerItem.playerItem.removeClass(Player.SELECTED_ITEM_HIGHLIGHT_CLASS);
      return Player.pause();
    }
  }

  static setSelectedCommonAfter() {
    Player.syncPrevAndNextButtonState();
    const {
      selectedEventHandleres
    } = Player.selectedPlayerItem;
    return Array.from(selectedEventHandleres).map((handler) => // forEach使うとレガシIEが死ぬ
      handler());
  }

  static setSelectedPlayerItem(playerItem) {
    Player.setSelectedCommonBefore();
    Player.selectedPlayerItemIndex = jQuery.inArray(playerItem, Player.playerItems);
    Player.selectedPlayerItem = playerItem;
    return Player.setSelectedCommonAfter();
  }

  static setSelectedPlayerItemIndex(index) {
    Player.setSelectedCommonBefore();
    Player.selectedPlayerItemIndex = index;
    Player.selectedPlayerItem = Player.playerItems[index];
    return Player.setSelectedCommonAfter();
  }

  static syncPrevAndNextButtonState() {
    if (Player.selectedPlayerItemIndex === 0) {
      Player.buttonPrev.addClass("disabled");
    } else {
      Player.buttonPrev.removeClass("disabled");
    }

    if (Player.selectedPlayerItemIndex === (Player.playerItems.length - 1)) {
      return Player.buttonNext.addClass("disabled");
    } else {
      return Player.buttonNext.removeClass("disabled");
    }
  }

  static next() {
    if (Player.selectedPlayerItemIndex !== (Player.playerItems.length - 1)) {
      Player.setSelectedPlayerItemIndex(Player.selectedPlayerItemIndex + 1);
      return Player.load();
    }
  }

  static prev() {
    if (Player.selectedPlayerItemIndex !== 0) {
      Player.setSelectedPlayerItemIndex(Player.selectedPlayerItemIndex - 1);
      return Player.load();
    }
  }

  static transport(diffTime) {
    if (Player.loading) {
      return;
    }

    let nextTime = Player.getCurrentTime() + diffTime;
    const duration = Player.getDuration();
    if (nextTime < 0) {
      nextTime = 0;
    } else if (duration < nextTime) {
      nextTime = duration;
    }

    Player.setCurrentTime(nextTime);

    if (Player.checkEnded()) {
      return Player.pause();
    } else {
      return Player.syncPlayerItem();
    }
  }

  static getCurrentTimeLabel(seconds, millisecondDigits, songSize) {
    if (seconds == null) { seconds = Player.getCurrentTime(); }
    if (millisecondDigits == null) { millisecondDigits = 0; }
    if (songSize == null) { ({
      songSize
    } = Player.selectedPlayerItem); }
    if (millisecondDigits === 0) {
      seconds = Math.floor(seconds);
    }
    if (songSize < seconds) {
      seconds = songSize;
    }
    return Math.floor(seconds / 60).toString() + ":" + Player.getTimeLabelDigits((seconds % 60).toFixed(millisecondDigits).toString(),millisecondDigits);
  }

  static playAtIndex(index) {
    if (index < 0) {
      index = 0;
    } else if (Player.playerItems.length <= index) {
      index = Player.playerItems.length - 1;
    }

    Player.setSelectedPlayerItemIndex(index);
    return Player.load();
  }

  static syncPlayerItem() {
    if (Player.loading) {
      return;
    }

    const current_time = Player.getCurrentTime();
    let percentage = current_time / Player.selectedPlayerItem.getDuration();
    percentage = Player.roundPercentage(percentage);
    Player.selectedPlayerItem.syncWaveform(percentage);
    return Player.selectedPlayerItem.syncCurrentTimeLabel();
  }

  static setError() {
    Player.audioLoadError = true;
    return Player.playerErrorMessage.stop(true).fadeIn("fast");
  }

  static clearError() {
    if (Player.audioLoadError) {
      Player.audioLoadError = false;
      return Player.playerErrorMessage.stop(true).fadeOut("fast");
    }
  }

  //#####################################################################################################################
  // audio initialize and handlers
  //#####################################################################################################################
  static initializeAudio() {
    Player.initializeVolume();

    Player.audio.addEventListener(
      "loadeddata",
      Player.audioLoadedDataHandler
    );

    // iOS 17.4 だと loadeddata が発火しないため loadedmetadata で代用
    // https://forums.developer.apple.com/forums/thread/748001
    Player.audio.addEventListener(
      "loadedmetadata",
      Player.audioLoadedMetadataHandler
    );

    Player.audio.addEventListener(
      "timeupdate",
      Player.syncPlayerItem
    );

    Player.audio.addEventListener(
      "ended",
      Player.endedHandler
    );

    Player.audio.addEventListener(
      "error",
      Player.audioLoadErrorHandler
    );

    return Player.audio.addEventListener(
      "canplay",
      Player.audioCanplayHandler
    );
  }

  //#####################################################################################################################
  // common handler
  //#####################################################################################################################
  static audioLoadCompleteCommon() {
    // console.log('@audioLoadCompleteCommon')
    Player.loading = false;
    return Player.selectedPlayerItem.hideProgress();
  }

  static audioLoadedDataHandlerCommon() {
    if (!Player.loading) { return; }

    // console.log('@audioLoadedDataHandlerCommon')

    let currentTimePercentage = 0;
    if (Player.currentTimePercentageBuffer === null) {
      // プレイヤーのnextボタンとかで再生位置未指定の場合はこっち。再生具合を見て途中から再生
      currentTimePercentage = Player.selectedPlayerItem.getCurrentTimePercentageByWaveformPosition();
    } else {
      // 波形クリック等で再生位置指定の場合はこっち
      currentTimePercentage = Player.currentTimePercentageBuffer;
      Player.currentTimePercentageBuffer = null;
    }

    if (currentTimePercentage === 1) { // ロード時に再生終了しているときは最初から再生
      currentTimePercentage = 0;
    }

    if (currentTimePercentage !== 0) {
      Player.setCurrentTime(Player.getDuration() * currentTimePercentage);
    }

    Player.audioLoadCompleteCommon();
    return Player.play();
  }

  static audioLoadedDataHandler() {
    // console.log('@audioLoadedDataHandler')
    return Player.audioLoadedDataHandlerCommon();
  }

  static audioLoadedMetadataHandler() {
    // console.log('@audioLoadedMetadataHandler')
    return Player.audioLoadedDataHandlerCommon();
  }

  static audioLoadErrorHandler(event) {
    // console.log('@audioLoadErrorHandler')
    if (!Player.loadRetried && (event.target.error.code === MediaError.MEDIA_ERR_NETWORK)) {
      return Player.retryLoading();
    } else {
      Player.setError();
      Player.selectedPlayerItem.hideProgressForce();
      Player.audioLoadCompleteCommon();
      return Player.pause();
    }
  }

  static audioCanplayHandler() {
    // console.log('@audioCanplayHandler')
    return Player.loadRetried = false;
  }

  static endedHandler() {
    // console.log('@endedHandler')
    if (!Player.dragging) {
      return Player.pause();
    }
  }

  //#####################################################################################################################
  // audio setter and getter
  //#####################################################################################################################
  static getDuration() {
    return Player.audio.duration;
  }

  static getCurrentTime() {
    return Player.audio.currentTime;
  }

  static setCurrentTime(currentTime) {
    if (Player.audioLoadError) {
      return;
    }
    return Player.audio.currentTime = currentTime;
  }

  static getPaused() {
    if (Player.firstLoaded) {
      return Player.audio.paused;
    } else {
      return true;
    }
  }

  static getVolume() {
    return Player.audio.volume;
  }

  static setVolume(volume) {
    if (volume === -1) {
      volume = 1;
    } else if (volume < 0) {
      volume = 0;
    } else if (1 < volume) {
      volume = 1;
    }

    Player.audio.volume = volume;

    $(this.buttonVolume.children("img")).hide();
    if (volume === 0) {
      this.buttonVolumeMute.show();
    } else if (volume < 0.33) {
      this.buttonVolumeLow.show();
    } else if (volume < 0.66) {
      this.buttonVolumeHigh.show();
    } else {
      this.buttonVolumeMax.show();
    }

    const volumeGaugePercentage = (volume * 100) + "%";
    Player.buttonVolumeGauge.css("height", volumeGaugePercentage);
    if (volume !== 0) {
      return Player.volumeCache = volume;
    }
  }

  static checkEnded() {
    return Player.getDuration() === Player.getCurrentTime();
  }

  //#####################################################################################################################
  // audio functions
  //#####################################################################################################################
  static retryLoading() {
    Player.loadRetried = true;
    return Player.load();
  }

  static load() {
    // console.log('@load')

    Player.selectedPlayerItem.waveformPlayedImage.css("width", Player.selectedPlayerItem.waveform.width());
    Player.selectedPlayerItem.playerItem.addClass(Player.SELECTED_ITEM_HIGHLIGHT_CLASS);

    Player.firstLoaded = true;
    Player.loading = true;
    Player.clearError();

    const { audioUrl } = Player.selectedPlayerItem;

    Player.audio.setAttribute('src', audioUrl);
    Player.audio.load();
    Player.selectedPlayerItem.inWindow();
    return Player.selectedPlayerItem.showProgress();
  }

  static play() {
    // console.log('@play')
    if (Player.audioLoadError) {
      return;
    }

    if (Player.checkEnded()) {
      Player.setCurrentTime(0);
      Player.syncPlayerItem();
    }

    Player.audio.play();

    Player.selectedPlayerItem.playButton.children(':not(.btn-loading)').hide();
    Player.selectedPlayerItem.buttonImagePause.show();
    Player.selectedPlayerItem.progress.addClass('button-loading-played');

    Player.buttonPlay.children().hide();
    Player.buttonPlayPause.show();

    if (!Player.alreadyShowPlayerManual() && Player.enablePlaySyncOpenManual) {
      Player.manualPopupOpen();
    }

    // 埋め込みプレイヤーの場合、別iframe内の再生アイテムを止める
    return Array.from(parent.window.frames).map((frame) =>
      (() => { try {
        if ((frame !== window) && !frame.Player.getPaused()) { return frame.Player.pause(); }
      } catch (error) {} })());
  }
        // nothing required

  static pause() {
    // console.log('@pause')
    Player.audio.pause();

    Player.selectedPlayerItem.playButton.children().hide();
    Player.selectedPlayerItem.buttonImagePlayed.show();

    Player.buttonPlay.children().hide();
    return Player.buttonPlayPlay.show();
  }

  //#####################################################################################################################
  // shortcut key functions
  //#####################################################################################################################
  static setShortcutKeyHandler() {
    Player.document.mousedown(function(event) {
      const target = $(event.target);
      return Player.focused = Player.playerControls.find(target).length || $(".player-item").find(target).length;
    });

    return Player.document.keydown(function(event) {
      if (Player.focused) {
        switch (event.keyCode) {
          case 13: case 32: // Keyboard.ENTER, Keyboard.SPACE:
            event.preventDefault();
            return Player.selectedPlayerItem.playOrPause();

          case 37: // Keyboard.LEFT:
            event.preventDefault();
            return Player.transport(-Player.TRANSPORT_SECONDS);
          case 39: // Keyboard.RIGHT:
            event.preventDefault();
            return Player.transport(+Player.TRANSPORT_SECONDS);

          case 33: case 38: // Keyboard.PAGE_UP, Keyboard.UP:
            event.preventDefault();
            return Player.prev();
          case 34: case 40: // Keyboard.PAGE_DOWN, Keyboard.DOWN:
            event.preventDefault();
            return Player.next();
        }
      }
    });
  }

  //#####################################################################################################################
  // volume functions
  //#####################################################################################################################
  static mute() {
    Player.volumeCache = Player.getVolume();
    return Player.setVolume(0);
  }

  static clearMute() {
    return Player.setVolume(Player.volumeCache);
  }

  static storeVolume() {
    const volume = Player.getVolume();
    if (volume !== 0) {
      setCookie({ key: 'playerVolume', value: volume })
      return $.ajax({
        type: 'POST',
        url: '/audio/store_player_volume',
        data: {volume: Player.getVolume()}
        // 特に重要ではないので、エラーハンドリングしない
      });
    }
  }

  //#####################################################################################################################
  // volume handlers common
  //#####################################################################################################################
  static getVolumePercentage(y) {
    y = (Player.buttonVolumeTrack.offset().top - y) + Player.buttonVolumeTrack.height();
    const percentage = y / Player.buttonVolumeTrack.height();
    return Player.roundPercentage(percentage);
  }

  static volumeMouseHandler(event, y) {
    Player.volumeDragging = true;
    return Player.setVolume(Player.getVolumePercentage(y));
  }

  static volumeDragCompleteHandler() {
    Player.volumeDragging = false;
    Player.storeVolume();
    if (!Player.volumeHovering) {
      return Player.buttonVolumeControls.hide();
    }
  }

  //#####################################################################################################################
  // volume handlers for PC
  //#####################################################################################################################
  static getYforPC(event) {
    return event.pageY;
  }

  static volumeMouseDownHandler(event) {
    return Player.volumeMouseHandler(event, Player.getYforPC(event));
  }

  static volumeMouseMoveHandler(event) {
    return Player.volumeMouseHandler(event, Player.getYforPC(event));
  }

  static volumeMouseUpHandler(event) {
    return Player.volumeDragCompleteHandler();
  }

  //#####################################################################################################################
  // tools
  //#####################################################################################################################
  static inWindow(target, params) {
    if (!Player.forrowSelectedItem) { return; }

    const windowObj = $(window);
    let offsetTop = 0;
    let offsetBottom = 0;
    if (params.offsetTop) {
      ({
        offsetTop
      } = params);
    }
    if (params.offsetBottom) {
      ({
        offsetBottom
      } = params);
    }

    const top    = windowObj.scrollTop();
    const bottom = (windowObj.height() + windowObj.scrollTop()) - offsetBottom;
    const left   = windowObj.scrollLeft();
    const right  = windowObj.width() + windowObj.scrollLeft();

    if (!((target.offset().top - offsetTop) >= top)) {
      windowObj.scrollTop(target.offset().top - offsetTop);
    }

    if (!((target.offset().top  + target.height()) <= bottom)) {
      windowObj.scrollTop(
        (windowObj.scrollTop() + target.offset().top  + target.height()) - bottom
      );
    }

    if (!(target.offset().left  >= left)) {
      windowObj.scrollLeft(target.offset().left);
    }

    if  (!((target.offset().left  + target.width()) <= right)) {
      return windowObj.scrollLeft(
        (windowObj.scrollLeft() + target.offset().left  + target.width()) - right
      );
    }
  }

  static getTimeLabelDigits(string, millisecondDigits) {
    if (millisecondDigits == null) { millisecondDigits = 0; }
    if (((string.length === 1) && (millisecondDigits === 0)) || ((millisecondDigits > 0) && (string.length < (3 + millisecondDigits)))) {
      return "0" + string;
    } else {
      return string;
    }
  }

  static roundPercentage(percentage) {
    if (1 < percentage) {
      percentage = 1;
    } else if (percentage < 0) {
      percentage = 0;
    }
    return percentage;
  }

  static manualPopupOpen() {
    $('#player-manual-popup').modal('show');
    $('#player-manual-popup').on('hidden.bs.modal', () => Player.focused = true);

    if (!Player.alreadyShowPlayerManual()) {
      return Player.mapualPopupCallAjax();
    }
  }

  static alreadyShowPlayerManual() {
    return $('#player-manual-popup').attr('data-already-show-popup') === 'true';
  }

  static mapualPopupCallAjax() {
    $.ajax({
      type: 'POST',
      url: '/account/player_popup'
    });
    return $('#player-manual-popup').attr('data-already-show-popup', 'true');
  }
}


//#######################################################################################################################
//
//
// PlayerItem class (instance only)
//
//
//#######################################################################################################################
class PlayerItem {
  //#####################################################################################################################
  //
  // instance methods and properties
  //
  //#####################################################################################################################
  constructor(playerItem) {
    this.index = Player.playerItems.length;

    this.playerItem = playerItem;
    this.playButton = $(".play-button", playerItem);
    this.currentTimeLabel = $(".current_time_label", playerItem);
    this.buttonImagePlay = $(".play_button_img", this.playButton);
    this.buttonImagePause = $(".stop_button_img", this.playButton);
    this.buttonImagePlayed = $(".played_button_img", this.playButton);
    this.progress = $(".btn-loading", this.playButton);
    this.cueButton = $(".btn-cue", playerItem);

    this.loggingAudioId = this.playButton.data("logging_audio_id");
    this.audioUrl = this.playButton.data("audio_url");
    this.songSize = this.playButton.data("song_size");
    if (this.songSize === 0) {
      this.songSize = 1;
    }

    this.selectedEventHandleres = [];
    this.waveformHovering = false;

    const waveformOuter = $(".waveform-outer", playerItem);
    this.waveform = $(".waveform", waveformOuter);
    this.waveformPlayed = $(".waveform-played", this.waveform);
    this.hoverPosition = $(".player-hover-position", this.waveform);
    this.hoverPositionLabel = $(".player-hover-position-label p", this.hoverPosition);

    this.waveformPlayedImage = $(".waveform-played-image", this.waveformPlayed);
    // @waveformPlayedImage.css("width", @waveform.width()); # resizeイベントで処理するのでここでは不要になった

    const millisecondDigits = this.playButton.data("millisecond_digits");
    if ((millisecondDigits !== null) && !isNaN(millisecondDigits)) {
      this.millisecondDigits = Number(millisecondDigits);
    } else {
      this.millisecondDigits = 0;
    }

    this.setPlayButtonClickHandler();
    this.setCueButtonClickHandler();
    this.setWaveformHoverHandler();

    new Slider(
      {
        target: this.waveform,
        mouseDownHandler: this.waveformMouseDownHandler,
        mouseMoveHandler: this.waveformMouseMoveHandler,
        mouseUpHandler: this.waveformMouseUpHandler,
        touchStartHandler: this.waveformTouchStartHandler,
        touchMoveHandler: this.waveformTouchMoveHandler,
        touchEndHandler: this.waveformTouchEndHandler,
        playerItem: this
      }
    );
  }

  //#####################################################################################################################
  // instance methods
  //#####################################################################################################################
  checkSameCurrentSelectedPlayerItem() {
    return Player.firstLoaded && (Player.selectedPlayerItemIndex === this.index);
  }

  play() {
    // console.log('play')
    if (this.checkSameCurrentSelectedPlayerItem()) {
      if (Player.getPaused()) {
        return Player.play();
      }
    } else {
      Player.setSelectedPlayerItem(this);
      return Player.load();
    }
  }

  cue() {
    if (this.checkSameCurrentSelectedPlayerItem()) {
      Player.setCurrentTime(0);
      Player.syncPlayerItem();
      if (Player.getPaused()) {
        return Player.play();
      }
    } else {
      Player.setSelectedPlayerItem(this);
      Player.setCurrentTime(0);
      Player.syncPlayerItem();
      return Player.load();
    }
  }

  playOrPause() {
    if (this.checkSameCurrentSelectedPlayerItem()) {
      if (Player.getPaused()) {
        return this.play();
      } else {
        return Player.pause();
      }
    } else {
      return this.play();
    }
  }

  playOrPauseByLink(event) {
    this.playOrPause();
    return event.preventDefault();
  }

  getCurrentTimePercentage(x) {
    x = x - this.waveform.offset().left;
    const percentage = x / this.waveform.width();
    return Player.roundPercentage(percentage);
  }

  showProgress() {
    return this.progress.show();
  }

  hideProgress() {
    return this.hideProgressForce();
  }

  hideProgressForce() {
    return this.progress.hide();
  }

  syncWaveformPlayedImageSize() {
    return this.waveformPlayedImage.css("width", this.waveform.width());
  }

  syncWaveform(percentage) {
    return this.waveformPlayed.css("width", this.waveform.width() * percentage);
  }

  syncPositionLabel(percentage) {
    this.hoverPosition.css("left", this.waveform.width() * percentage);
    const duration = this.getDuration();
    return this.hoverPositionLabel.text(Player.getCurrentTimeLabel(duration * percentage, this.millisecondDigits, duration));
  }

  syncCurrentTimeLabel(seconds = null) {
    return this.currentTimeLabel.text(Player.getCurrentTimeLabel(seconds, this.millisecondDigits));
  }

  getCurrentTimePercentageByWaveformPosition() {
    if (this.waveform.length === 0) {
      return 0;
    } else {
      const totalWidth = this.waveform.width();
      const playedWidth = this.waveformPlayed.width();
      const percentage = playedWidth / totalWidth;
      return Player.roundPercentage(percentage);
    }
  }

  inWindow() {
    return Player.inWindow(this.playerItem, {offsetTop: 50, offsetBottom: 100}); // パッケージ販売リンク表示中は100、なくなったら50に戻すこと
  }

  addSelectedEventHandler(handler) {
    return this.selectedEventHandleres.push(handler);
  }

  showTooltip() {
    return this.hoverPosition.show();
  }

  hideTooltip() {
    return this.hoverPosition.hide();
  }

  getDuration() {
    if (!Player.loading && this.checkSameCurrentSelectedPlayerItem()) {
      return Player.getDuration();
    } else {
      return this.songSize;
    }
  }

  //#####################################################################################################################
  // player item handlers common
  //#####################################################################################################################
  waveformMouseHandler(event, x) {
    // console.log('waveformMouseHandler')
    Player.dragging = true;

    this.play();

    const currentTimePercentage = this.getCurrentTimePercentage(x);
    this.syncWaveform(currentTimePercentage);
    this.syncPositionLabel(currentTimePercentage);
    this.syncCurrentTimeLabel(this.getDuration() * currentTimePercentage);

    if (Player.loading) {
      return Player.currentTimePercentageBuffer = currentTimePercentage;
    } else {
      const currentTime = Player.getDuration() * currentTimePercentage;
      Player.setCurrentTime(currentTime);
      return this.syncCurrentTimeLabel(currentTime);
    }
  }

  waveformDragCompleteHandler() {
    Player.dragging = false;

    if (!this.waveformHovering) {
      this.hideTooltip();
    }

    if (Player.checkEnded()) {
      return Player.endedHandler();
    }
  }

  setPlayButtonClickHandler() {
    const playerItem = this;
    return this.playButton.click(() => playerItem.playOrPause());
  }

  setCueButtonClickHandler() {
    const playerItem = this;
    return this.cueButton.click(() => playerItem.cue());
  }

  //#####################################################################################################################
  // player item handlers for PC for transport
  //#####################################################################################################################
  getXforPC(event) {
    return event.clientX;
  }

  waveformMouseDownHandler(event, slider) {
    slider.params.playerItem.waveformMouseHandler(event, slider.params.playerItem.getXforPC(event));
    return slider.params.playerItem.showTooltip();
  }

  waveformMouseMoveHandler(event, slider) {
    return slider.params.playerItem.waveformMouseHandler(event, slider.params.playerItem.getXforPC(event));
  }

  waveformMouseUpHandler(event, slider) {
    return slider.params.playerItem.waveformDragCompleteHandler();
  }

  //#####################################################################################################################
  // player item handlers for PC for tooltip
  //#####################################################################################################################
  setWaveformHoverHandler() {
    const playerItem = this;

    this.waveform.hover(
      function() {
        if (!Player.mobile) {
          if (!Player.dragging) {
            playerItem.waveformHovering = true;
            return playerItem.showTooltip();
          }
        }
      }
      ,
      function() {
        playerItem.waveformHovering = false;
        if (!Player.dragging) {
          return playerItem.hideTooltip();
        }
    });

    return this.waveform.mousemove(function(event) {
      if (!Player.dragging) {
        return playerItem.syncPositionLabel(playerItem.getCurrentTimePercentage(playerItem.getXforPC(event)));
      }
    });
  }

  //#####################################################################################################################
  // player item handlers for mobile or touch screen
  //#####################################################################################################################
  getXforTouch(event) {
    if (event.changedTouches) {
      return event.changedTouches[0].pageX;
    } else if (event.originalEvent && event.originalEvent.changedTouches) {
      return event.originalEvent.changedTouches[0].pageX;
    } else {
      return 0;
    }
  }

  waveformTouchStartHandler(event, slider) {
    slider.params.playerItem.waveformMouseHandler(event, slider.params.playerItem.getXforTouch(event));
    return slider.params.playerItem.showTooltip();
  }

  waveformTouchMoveHandler(event, slider) {
    return slider.params.playerItem.waveformMouseHandler(event, slider.params.playerItem.getXforTouch(event));
  }

  waveformTouchEndHandler(event, slider) {
    slider.params.playerItem.waveformDragCompleteHandler();
    return slider.params.playerItem.hideTooltip();
  }
}

//#######################################################################################################################
//
//
// Slider component control class
//   target:
//   mouseDownHandler:
//   mouseMoveHandler:
//   mouseUpHandler:
//   touchStartHandler:
//   touchMoveHandler:
//   touchEndHandler:
//   and dynamic event options more...
//
//
//#######################################################################################################################
class Slider {
  static initClass() {
    this.activeSlider = null;
     // やっぱ完全なクラスの再現はできないっぽい・・・・仕方なくstatic
  }

  static isPlayingItemOnMobile(event) {
    if (Player.mobile) {
      return (event.currentTarget === Player.selectedPlayerItem.waveform[0]) && !Player.getPaused();
    } else {
      return true;
    }
  }

  constructor(params) {
    this.params = params;
    const slider = this;

    this.params.target.mousedown(function(event) {
      if (Slider.isPlayingItemOnMobile(event)) {
        Slider.commonHandler(event);
        Slider.activeSlider = slider; // やっぱ完全なクラスの再現はできないっぽい・・・・仕方なくstatic
        if (slider.params.mouseDownHandler) {
          slider.params.mouseDownHandler(event, slider);
        }

        Player.document.off("mousemove", Slider.mouseMoveHandler);
        return Player.document.on("mousemove", Slider.mouseMoveHandler);
      }
    });

    Player.document.mouseup(function(event) {
      Player.document.off("mousemove", Slider.mouseMoveHandler);
      if ((Slider.activeSlider !== null) && Slider.activeSlider.params.mouseUpHandler) {
        Slider.activeSlider.params.mouseUpHandler(event, Slider.activeSlider);
      }

      return Slider.activeSlider = null;
    });

    try {
      slider.params.target.on(
        "touchstart",
        function(event) {
          if (Slider.isPlayingItemOnMobile(event)) {
            Slider.commonHandler(event);
            Slider.activeSlider = slider; // やっぱ完全なクラスの再現はできないっぽい・・・・仕方なくstatic
            if (slider.params.touchStartHandler) {
              slider.params.touchStartHandler(event, slider);
            }

            Player.document.off("touchmove", Slider.touchMoveHandler);
            return Player.document.on("touchmove", Slider.touchMoveHandler);
          }
      });
      Player.document.on(
        "touchend",
        function(event) {
          Player.document.off("touchmove", Slider.touchMoveHandler);
          if ((Slider.activeSlider !== null) && Slider.activeSlider.params.touchEndHandler) {
            Slider.activeSlider.params.touchEndHandler(event, Slider.activeSlider);
          }

          return Slider.activeSlider = null;
      });
    } catch (error) {}
      // nothing required

    if (Player.mobile) {
      this.params.target.click(function(event) {
        slider.params.playerItem.waveformMouseHandler(event, slider.params.playerItem.getXforPC(event));
        return slider.params.playerItem.waveformDragCompleteHandler();
      });
    }
  }

  static commonHandler(event) {
    try {
      return event.preventDefault();
    } catch (error) {}
  }
      // nothing required

  static mouseMoveHandler(event) {
    Slider.commonHandler(event);
    if ((Slider.activeSlider !== null) && Slider.activeSlider.params.mouseMoveHandler) {
      return Slider.activeSlider.params.mouseMoveHandler(event, Slider.activeSlider);
    }
  }

  static touchMoveHandler(event) {
    Slider.commonHandler(event);
    if ((Slider.activeSlider !== null) && Slider.activeSlider.params.touchMoveHandler) {
      return Slider.activeSlider.params.touchMoveHandler(event, Slider.activeSlider);
    }
  }
}
Slider.initClass();

//#######################################################################################################################
//
//
// execute
//
//
//#######################################################################################################################
$(function() {
  window.Player = Player;
  window.Player.initialize();

  if ($(".player-item").length === 0) {
    return $("#player-controller").hide();
  }
});
