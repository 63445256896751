document.addEventListener('DOMContentLoaded', () => {
  const searchUrlFields = document.querySelectorAll('.js-search-url')

  if (searchUrlFields.length) {
    const updateButton = (currentField) => {
      const currentButton = currentField.parentNode.querySelector('.js-search-url-confirm')

      if (currentField.value) {
        currentButton.classList.remove('disabled')
        currentButton.classList.remove('btn-outline-secondary')
        currentButton.classList.add('btn-info')
        currentButton.href = currentField.value
      } else {
        currentButton.classList.add('disabled')
        currentButton.classList.remove('btn-info')
        currentButton.classList.add('btn-outline-secondary')
        currentButton.href = ''
      }
    }

    searchUrlFields.forEach((searchUrlField) => {
      searchUrlField.oninput = (e) => updateButton(e.target)
      searchUrlField.onchange = (e) => updateButton(e.target)
      searchUrlField.onkeydown = (e) => updateButton(e.target)
      searchUrlField.onkeyup = (e) => updateButton(e.target)
      updateButton(searchUrlField)
    })
  }
})
