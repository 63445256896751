/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import $ from 'jquery'

$(function() {
  // トップへ戻るボタン
  const scrollTopButton = $('#scroll-top')
  scrollTopButton.click(() => $('body, html').animate({ scrollTop: 0 }, 500))
  const updateScrollTopButton = function() {
    if ($(window).scrollTop() > 500) {
      return scrollTopButton.addClass('active')
    } else {
      return scrollTopButton.removeClass('active')
    }
  }
  $(window).scroll(updateScrollTopButton)
  return updateScrollTopButton()
})
