/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(function() {
  if ($('#new_admin_bill_wait_payment_bill_search_form').is('*')) {
    return $(document).on('click', '.js-remind-bill-payment', e => $.ajax({
      type: 'POST',
      url: '/admin_bill/remind',
      data: { id: $(e.target).data('id') },
      dataType: 'html',
      success(res) {
        return $(e.target).closest('.js-remind').html("送付済み");
      }
    }));
  }
});

