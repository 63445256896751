import React from 'react'
import Highlighter from 'react-highlight-words'
import { menuIconClassName, includesSearchTextInMenu, includesCurrentPathInMenu, removeUnusedSelectorCharacter } from './utils'

export const linkWithSearchText = (link, searchText) => {
  const { name, url } = link
  const navLinkClassName = url === location.pathname ? 'nav-link active' : 'nav-link'
  if (searchText.length === 0) {
    return <a href={url} className={navLinkClassName}>{name}</a>
  } else if ((new RegExp(searchText, 'i')).test(name)) {
    return (
      <a href={url} className={navLinkClassName}>
        <Highlighter
          searchWords={[searchText]}
          textToHighlight={name}
        />
      </a>
    )
  } else {
    return <span />
  }
}
const SideMenuCard = ({ searchText, menuCount, menu: { title, links } }) => {
  const id = `side-menu-${removeUnusedSelectorCharacter(title)}`
  return (
    includesSearchTextInMenu(links, searchText) ? (
      <section className="card my-3">
        <a href={`#${id}`} className="text-decoration-none" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls={id}>
          <div className="card-header d-flex justify-content-between align-items-center">
        <span className="text-dark">
          <i className={`${menuIconClassName(title)} fa-lg text-body-secondary me-1`} />
          {title}
        </span>
            <i className="fass fa-chevron-down" />
          </div>
        </a>
        <div className={`collapse ${menuCount === 1 || searchText.length > 0 || includesCurrentPathInMenu(links) ? 'show' : ''}`} id={id}>
          <ul className="card-body p-0 nav flex-column">
            {links.filter(({ permitted }) => permitted === 'true').map((link) => (
              <li className="nav-item" key={link.name}>
                {linkWithSearchText(link, searchText)}
              </li>
            ))}
          </ul>
        </div>
      </section>
    ) : <div />
  )
}

export default SideMenuCard
