// rails-ujs
import Rails from '@rails/ujs'
window.Rails = Rails
Rails.start()

// jquery-ui
import 'jquery-ui/dist/jquery-ui.min'
import 'jquery-ui/dist/themes/base/jquery-ui.min.css'

// cocoon
import '@nathanvda/cocoon'
