// https://davidwalsh.name/detect-webp

// ブラウザが WebP をサポートしているかどうかの判定
export const supportWebp = async () => {
  if (!self.createImageBitmap) return false

  const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA='
  const blob = await fetch(webpData).then((r) => r.blob())
  return createImageBitmap(blob).then(
    () => true,
    () => false,
  )
}

// .js-handle-webp を対象に、
// WebP がサポートされていれば .webp を、そうでなければ .no-webp を付与する
document.addEventListener('DOMContentLoaded', async () => {
  const isSupportWebp = await supportWebp()
  document.querySelectorAll('.js-handle-webp').forEach((handleWebPElement) => {
    if (isSupportWebp) {
      handleWebPElement.classList.add('webp')
    } else {
      handleWebPElement.classList.add('no-webp')
    }
  })
})
