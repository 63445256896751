document.addEventListener('DOMContentLoaded', () => {
  const targetPage = document.querySelector('.js-mrc2023-final-result')
  if (targetPage) {
    // 各部門のナビゲーション制御
    const nav = document.querySelector('.js-mrc2023-final-result-audios-nav')
    const navLinkA = document.querySelector('.js-mrc2023-final-result-audios-nav-link-a')
    const navLinkB = document.querySelector('.js-mrc2023-final-result-audios-nav-link-b')
    const categoryA = document.querySelector('.js-mrc2023-final-result-audios-category-a')
    const categoryB = document.querySelector('.js-mrc2023-final-result-audios-category-b')

    let visibilityHiddenTimeoutId
    const showNav = () => {
      if (nav.dataset.active === 'true') return

      clearTimeout(visibilityHiddenTimeoutId)
      nav.style.visibility = 'visible'
      nav.dataset.active = 'true'
    }
    const hideNav = () => {
      if (nav.dataset.active === 'false') return

      const transitionDuration = parseFloat(getComputedStyle(nav).transitionDuration)
      nav.dataset.active = 'false'
      visibilityHiddenTimeoutId = setTimeout(() => nav.style.visibility = 'hidden', transitionDuration * 1000)
    }

    const handleNav = () => {
      const categoryATopPosition    = parseInt(window.scrollY + categoryA.getBoundingClientRect().top)
      const categoryABottomPosition = parseInt(window.scrollY + categoryA.getBoundingClientRect().bottom)
      const categoryBTopPosition    = parseInt(window.scrollY + categoryB.getBoundingClientRect().top)
      const categoryBBottomPosition = parseInt(window.scrollY + categoryB.getBoundingClientRect().bottom)

      if (categoryATopPosition <= window.scrollY && window.scrollY < categoryABottomPosition) {
        showNav()
        navLinkA.dataset.active = 'true'
        navLinkB.dataset.active = 'false'
      } else if (categoryBTopPosition <= window.scrollY && window.scrollY < categoryBBottomPosition) {
        showNav()
        navLinkA.dataset.active = 'false'
        navLinkB.dataset.active = 'true'
      } else {
        hideNav()
        navLinkA.dataset.active = 'false'
        navLinkB.dataset.active = 'false'
      }
    }
    document.addEventListener('scroll', handleNav)
    document.addEventListener('resize', handleNav)
    handleNav()

    // プレイヤーの制御
    const players = document.querySelectorAll('.js-mrc2023-final-result-audios-list-item-player')
    const handlePlay = (e) => {
      players.forEach((player) => {
        if (player !== e.target) player.pause()
      })
    }
    const handleVolumeChange = (e) => {
      const volume = e.target.volume
      players.forEach((player) => player.volume = volume)
    }

    players.forEach((player) => {
      player.volume = .7
      player.addEventListener('play', handlePlay)
      player.addEventListener('volumechange', handleVolumeChange)
    })

    // ヤマハ賞の背景の視差効果
    const positionBase = document.querySelector('.js-mrc2023-final-result-yamaha')
    const target = document.querySelector('.js-mrc2023-final-result-yamaha-bg')
    const bgParallax = () => {
      const positionTop = positionBase.getBoundingClientRect().top
      const positionBottom = positionBase.getBoundingClientRect().bottom
      const scrollPercentage = ((window.innerHeight - positionTop) / (positionBottom - positionTop + window.innerHeight)) * 100
      target.style.backgroundPositionY = `${scrollPercentage}%`
    }
    window.addEventListener('scroll', bgParallax)
    window.addEventListener('resize', bgParallax)
    bgParallax()
  }
})
