import React, { useState, useRef } from 'react'
import SearchField from './SearchField'
import TargetAudiosTable from './TargetAudiosTable'
import SelectedAudio from './SelectedAudio'
import * as utils from './utils'

const VariationTargetAudios = ({ audios, input_name, input_id, selected_parent_id }) => {
  const [isOpenList, setOpenList] = useState(false)
  const documentClickHandler = (e) => {
    if (popupRef.current && popupRef.current.contains(e.target)) return
    if (searchFieldWrapperRef.current.contains(e.target)) return
    handleCloseList()
  }
  const handleOpenList = async () => {
    document.body.addEventListener('click', documentClickHandler)
    await setOpenList(true)
    searchFieldRef.current.focus()
  }
  const handleCloseList = () => {
    document.body.removeEventListener('click', documentClickHandler)
    setOpenList(false)
    setSearchWord('')
    setSearchIndex(-1)
  }

  const [selectedAudioId, selectAudio] = useState(selected_parent_id)
  const selectedAudio = audios.find(({ id }) => id === selectedAudioId)
  const handleSelectAudio = (id) => {
    selectAudio(id)
    handleCloseList()
  }

  const [searchWord, setSearchWord] = useState('')
  const [searchIndex, setSearchIndex] = useState(-1)
  const targetAudios = searchWord.length ? utils.searchAudios(audios, searchWord) : audios

  const popupRef = useRef()
  const searchFieldRef = useRef()
  const searchFieldWrapperRef = useRef()
  return (
    <div className="variation-target-audios" onClick={() => !isOpenList && handleOpenList()}>
      <div ref={searchFieldWrapperRef}>
        {isOpenList ? (
          <SearchField
            audios={audios}
            targetAudios={targetAudios}
            searchIndex={searchIndex}
            searchWord={searchWord}
            setSearchIndex={setSearchIndex}
            handleSelectAudio={handleSelectAudio}
            setSearchWord={setSearchWord}
            searchFieldRef={searchFieldRef}
          />
        ) : (
          selectedAudio ? (
            <SelectedAudio selectedAudio={selectedAudio} selectedAudioId={selectedAudioId} />
          ) : (
            <span className="d-flex justify-content-between align-items-center">
              <span>作品を選択してください</span>
              <i className="fass fa-sort" />
            </span>
          )
        )}
      </div>
      {isOpenList && (
        <TargetAudiosTable
          targetAudios={targetAudios}
          selectedAudioId={selectedAudioId}
          searchWord={searchWord}
          searchIndex={searchIndex}
          handleSelectAudio={handleSelectAudio}
          popupRef={popupRef}
        />
      )}
      <input type="hidden" name={input_name} id={input_id} defaultValue={selectedAudioId} />
    </div>
  )
}

export default VariationTargetAudios
