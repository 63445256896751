document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-admin-playlist-search-form')) {
    const displayableTypeAllRadioButton = document.querySelector('.js-admin-playlist-search-form-displayable-type-all-radio-button')
    const displayableTypeFrontRadioButton = document.querySelector('.js-admin-playlist-search-form-displayable-type-front-radio-button')
    const sortTypeNewestRadioButton = document.querySelector('.js-admin-playlist-search-form-sort-type-newest-radio-button')
    const sortTypeSpecifiedRadioButton = document.querySelector('.js-admin-playlist-search-form-sort-type-specified-radio-button')

    const enableRadioButton = (radioButton) => {
      radioButton.disabled = false
    }

    const disableRadioButton = (radioButton) => {
      radioButton.disabled = true
    }

    if (displayableTypeAllRadioButton.checked) {
      disableRadioButton(sortTypeSpecifiedRadioButton)
      sortTypeNewestRadioButton.checked = true
    } else {
      enableRadioButton(sortTypeSpecifiedRadioButton)
    }

    displayableTypeAllRadioButton.addEventListener('change', (e) => {
      if (e.target.checked) {
        disableRadioButton(sortTypeSpecifiedRadioButton)
        sortTypeNewestRadioButton.checked = true
        sortTypeSpecifiedRadioButton.checked = false
      }
    })

    displayableTypeFrontRadioButton.addEventListener('change', (e) => {
      if (e.target.checked) {
        enableRadioButton(sortTypeSpecifiedRadioButton)
      }
    })
  }
})
