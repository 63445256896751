import $ from 'jquery'
import { Popover } from 'bootstrap'

document.addEventListener('DOMContentLoaded', () => {
  const headers = {
    'X-CSRF-Token' : $('meta[name="csrf-token"]').attr('content')
  }

  window.audioShowAddFavorite = function (button, audioId, loggedIn) {
    if (!loggedIn) {
      window.openLoginModal()
    } else {
      $.ajax({
        type: 'POST',
        url: '/audio/add_favorite_audio',
        data: { id: audioId },
        dataType: 'html',
        headers,
        success: () => {
          const buttonJq = $(button)
          buttonJq.addClass('btn-primary')
          buttonJq.removeClass('btn-outline-primary')
          buttonJq.find('i').removeClass('fasr')
          buttonJq.find('i').addClass('fass')
          buttonJq.addClass('js-delete-audio-favorite-button')
          buttonJq.removeAttr('onClick')
          buttonJq.attr('onClick', `showFavoriteAudioCategories(this, ${audioId})`)
          window.showFavoriteAudioCategories(button, audioId)
        }
      })
    }
  }

  window.audioShowDeleteFavorite = (button, audioId) => $.ajax({
    type: 'POST',
    url: '/audio/delete_favorite_audio',
    data: { id: audioId },
    dataType: 'html',
    headers,
    success: () => {
      const buttonJq = $(button)
      buttonJq.removeClass('btn-primary')
      buttonJq.addClass('btn-outline-primary')
      buttonJq.find('i').removeClass('fass')
      buttonJq.find('i').addClass('fasr')
      buttonJq.removeClass('js-delete-audio-favorite-button')
      buttonJq.removeAttr('onClick')
      buttonJq.attr('onClick', `audioShowAddFavorite(this, ${audioId}, true)`)
      window.closeFavoriteAudioCategories()
    }
  })

  window.showFavoriteAudioCategories = function (button) {
    if (button) {
      $.ajax({
        type: 'GET',
        url: button.dataset.url,
        dataType: 'html',
        headers,
        success: (res) => {
          const resObject = $($.parseHTML(res))
          const title = resObject.find('.title').html()
          const content = resObject.find('.content').html()
          Popover.getOrCreateInstance(button).dispose()
          new Popover(button, {
            html: true,
            sanitize: false,
            container: 'body',
            title,
            content,
            template: '<div class="popover js-favorite-audio-category-area" role="tooltip"><div class="arrow"></div><div class="popover-header"></div><div class="popover-body"></div></div>'
          }).show()
        }
      })
    }
  }

  window.closeFavoriteAudioCategories = () => {
    document.querySelectorAll('.js-favorite-audio-button[data-bs-toggle=popover]').forEach((popoverButton) => {
      const popover = Popover.getOrCreateInstance(popoverButton)
      popover.dispose()
    })
  }
  window.closeFavoriteAudioCategories()

  $(function () {
    const $body = $('body')
    $body.on('click', '.js-close-favorite-category-area', () => {
      window.closeFavoriteAudioCategories()
    })
    $body.on('click', '.js-delete-audio-favorite', (e) => {
      e.preventDefault()
      const audioId = e.target.dataset.audioId
      if (audioId) {
        const button = document.querySelector(`.js-delete-audio-favorite-button[data-audio-id="${audioId}"]`)
        if (button) {
          window.audioShowDeleteFavorite(button, audioId)
        } else {
          window.deleteFavorite(audioId)
        }
      }
    })
  })
})
