$(() => {
  const $form = $('.js-original-album-entry-audio-informations')

  if ($form.is('*')) {
    // ISRCの入力フォームを制御
    const $isrcInputter = $('input[name*=isrc_inputter]')
    const updateIsrcField = ($target) => {
      const $isrcField = $target.parent().parent().find('.js-isrc-field')
      if ($target.val() === 'admin') {
        $isrcField.val('').prop('readonly', true)
      } else {
        $isrcField.prop('readonly', false)
      }
    }
    $isrcInputter.on('change', (e) => updateIsrcField($(e.target)))
    $isrcInputter.filter(':checked').each((_, isrcInputterField) => updateIsrcField($(isrcInputterField)))

    // ページ離脱防止
    window.onbeforeunload = (e) => {
      e.returnValue = 'ページを離れようとしています。行った変更が保存されない可能性がありますが、よろしいですか？'
    }
    // 送信時はページ離脱防止を表示しない
    $form.on('submit', () => {
      window.onbeforeunload = null
    })

    // 試聴開始時間の入力フォームを制御
    const $clipPointField = $('.js-clip-point')
    const convertToHalfWidthNumber = (number) => number.replace(/[０-９：]/g, (char) => String.fromCharCode(char.charCodeAt(0) - 0xFEE0))
    const convertTimeToMinutes = (_minutes, _seconds) => {
      if (!_minutes && !_seconds) return

      const minutes = parseInt(convertToHalfWidthNumber(_minutes || '0'), 10)
      const seconds = parseInt(convertToHalfWidthNumber(_seconds || '0'), 10)
      return minutes * 60 + seconds
    }
    const convertTimeToNextField = ($target) => {
      const $minutesField = $target.parent().children('input[name=clip_point_minutes]')
      const $secondsField = $target.parent().children('input[name=clip_point_seconds]')
      const $clipPointHiddenField = $target.parent().next('input')
      $clipPointHiddenField.val(convertTimeToMinutes($minutesField.val(), $secondsField.val()))
    }
    $clipPointField.on('change keyup', (e) => convertTimeToNextField($(e.target)))
    $clipPointField.filter(':first-of-type').each((_, clipPointField) => convertTimeToNextField($(clipPointField)))

    // ボタンのスクロール時画面固定
    const $buttonArea = $('.js-button-area')
    const $backButton = $buttonArea.find('.btn-outline-secondary')
    const startFixedPosition = 2000
    let endFixedPosition = $buttonArea.offset().top + $buttonArea.outerHeight()
    let buttonAreaWidth = $('.contents-main').width()
    $buttonArea.css({ bottom: -$buttonArea.outerHeight() })
    const handleScroll = () => {
      const scrollPosition = $(window).scrollTop()
      const currentPosition = scrollPosition + $(window).height()
      if (currentPosition < endFixedPosition) {
        if (currentPosition > startFixedPosition && currentPosition < endFixedPosition - $buttonArea.outerHeight()) {
          $buttonArea.stop()
          $buttonArea.css({ position: 'fixed', zIndex: 10, bottom: 0, width: buttonAreaWidth })
          $backButton.fadeOut('fast')
        }
      } else {
        $buttonArea.stop()
        $buttonArea.css({ position: 'static', bottom: -$buttonArea.outerHeight(), width: 'auto' })
        $backButton.fadeIn('fast')
      }
    }
    $(window).resize(() => {
      endFixedPosition = $buttonArea.offset().top + $buttonArea.outerHeight()
      buttonAreaWidth = $('.contents-main').width()
    })
    $(window).scroll(handleScroll)
    handleScroll()
  }
})
