$(() => {
  // 翻訳
  const $jsTranslationBtns = $('.js-translation-btn')

  if ($jsTranslationBtns.is('*')) {
    $jsTranslationBtns.each((index, translationBtn) => {
      const $translationBtn = $(translationBtn)
      const $source = $($translationBtn.data('from-input'))
      const $target = $($translationBtn.data('target-input'))

      $translationBtn.on('click', (e) => {
        const $button = $(e.target)
        const $buttonIcon = $button.find('.fa-sync-alt')
        $button.prop('disabled', true)
        $buttonIcon.addClass('fa-spin')

        const formdata = new FormData()
        formdata.append('authenticity_token', $('meta[name="csrf-token"]').attr('content'))
        formdata.append('text', $source.val())

        fetch(
          $translationBtn.data('url'),
          {
            body: formdata,
            method: 'POST'
          }
        ).then(response => {
          if (response.ok) {
            return response.json()
          } else {
            return response.text().then((error) => { throw new Error(error) })
          }
        }).then((response) => {
          $target.val(response.result).change()
          $button.prop('disabled', false)
          $buttonIcon.removeClass('fa-spin')
        }).catch((error) => {
          alert(error.message)
          $button.prop('disabled', false)
          $buttonIcon.removeClass('fa-spin')
        })
      })
    })
  }
})
