export const includesSearchTextInMenu = (links, searchText) => {
  if (searchText.length === 0) return true
  const regexp = new RegExp(searchText.replace(/[-\/\\^$*+?.()|\[\]{}]/g, '\\$&'), 'i')
  return links.some((link) => regexp.test(link.name))
}

export const includesCurrentPathInMenu = (links) => (
  links.some(({ url }) => url === location.pathname)
)

export const menuIconClassName = (title) => {
  switch (title) {
  case '音源・タグ': return 'fass fa-music'
  case 'アカウント': return 'fas fa-users'
  case 'カスタマー': return 'fas fa-user-tie'
  case 'クリエイター': return 'fas fa-user-astronaut'
  case '変更申請': return 'fasr fa-edit'
  case '通知': return 'fasr fa-envelope'
  case 'ログ': return 'fasr fa-file-alt'
  case '管理': return 'fas fa-user-cog'
  case 'その他': return 'fass fa-cog'
  case '月初締め作業・入金関連': return 'fass fa-money-check-alt'
  case 'PIXTA': return 'fab fa-product-hunt'
  case '一括登録': return 'fas fa-cloud-upload-alt'
  case '記事': return 'fasr fa-newspaper'
  case '産総研類似ベンチマーク': return 'fass fa-flask'
  case '定額制プロプラン': return 'fass fa-sun'
  case '定額制個人プラン': return 'fasr fa-sun'
  case '新定額制プラン': return 'fasl fa-cog'
  case '音楽配信': return 'fass fa-compact-disc'
  case 'プレイリスト': return 'fass fa-list-music'
  case 'コンテスト': return 'fasr fa-trophy'
  }
}

export const removeUnusedSelectorCharacter = (selector) => selector.replace(/[ !"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '')
