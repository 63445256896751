import { Modal } from 'bootstrap'

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('[data-controller="footage"]')) {
    document.querySelectorAll('[data-controller="preview"]').forEach((previewElement) => {
      // サムネイルの制御
      const thumbnailVideo = previewElement.querySelector('[data-preview-target="video"]')
      const startThumbnailPreview = () => {
        thumbnailVideo.currentTime = 0
        thumbnailVideo.play()
      }
      const stopThumbnailPreview = () => {
        setTimeout(thumbnailVideo.pause(), 240)
      }
      previewElement.addEventListener('mouseenter', startThumbnailPreview)
      previewElement.addEventListener('mouseleave', stopThumbnailPreview)

      // モーダルの制御
      const previewModal = previewElement.querySelector('[data-controller="previewModal"]')
      const previewModalVideo = previewModal.querySelector('[data-preview-modal-target="video"]')
      const startModalPreview = () => {
        previewModalVideo.currentTime = 0
        previewModalVideo.play()
      }
      const stopModalPreview = () => {
        previewModalVideo.pause()
      }
      previewModal.addEventListener('show.bs.modal', startModalPreview)
      previewModal.addEventListener('hide.bs.modal', stopModalPreview)
    })
  }

  // 規約同意のチェックボックスの制御
  const agreementModals = document.querySelectorAll('[data-controller="agreementModal"]')
  if (agreementModals.length > 0) {
    agreementModals.forEach((agreementModal) => {
      const agreementModalCheckBox = agreementModal.querySelector('[data-agreement-modal-target="checkBox"]')
      const agreementModalBtn = agreementModal.querySelector('[data-agreement-modal-target="btn"]')
      agreementModalCheckBox.addEventListener('change', (e) => {
        if (e.target.checked) {
          agreementModalBtn.classList.add('btn-primary')
          agreementModalBtn.classList.remove('btn-secondary')
          agreementModalBtn.disabled = false
        } else {
          agreementModalBtn.classList.add('btn-secondary')
          agreementModalBtn.classList.remove('btn-primary')
          agreementModalBtn.disabled = true
        }
      })
      agreementModalBtn.addEventListener('click', () => {
        const modal = Modal.getOrCreateInstance(agreementModal)
        modal.hide()
      })
    })
  }
})
