document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-admin-playlist-form')) {
    const frontDisplayableCheckBox = document.querySelector('.js-admin-playlist-form-front-displayable-check-box')
    const frontCollapse = document.querySelector('.js-admin-playlist-form-front-collapse')

    // MEMO: collapse の開閉制御は、Bootstrap 4 だと jQuery を使用しないと実装できない
    // Bootstrap 5 にバージョンアップしたら jQuery  を使わないように書き直したい
    const changeCollapse = (isShow, collapse) => {
      if (isShow) {
        $(collapse).collapse('show')
        collapse.closest('section').querySelectorAll('.js-admin-playlist-form-displayable-item').forEach((item) => {
          item.disabled = false
        })
      } else {
        $(collapse).collapse('hide')
        collapse.closest('section').querySelectorAll('.js-admin-playlist-form-displayable-item').forEach((item) => {
          item.disabled = true
        })
      }
    }

    changeCollapse(frontDisplayableCheckBox.checked, frontCollapse)

    frontDisplayableCheckBox.addEventListener('change', (e) => {
      changeCollapse(e.target.checked, frontCollapse)
    })
  }
})
