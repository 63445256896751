$(() => {
  // 作品ファイルアップロード
  const $audioFreeTempo = $('.js-audio-free-tempo')

  if ($audioFreeTempo.is('*')) {
    function bpm_check(){
      const $audioBpm = $('.js-audio-bpm')

      if($audioFreeTempo.prop('checked')){
        $audioBpm.attr('disabled', 'disabled');
        $audioBpm.val('');
      }else{
        $audioBpm.removeAttr('disabled');
      }
    }

    bpm_check();
    $audioFreeTempo.click(function(){
      bpm_check();
    });
  }
})
