document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-setsugetsuka')) {
    // 背景画像に視差効果を付ける
    const positionBase = document.querySelector('.js-setsugetsuka-main')
    const target = document.querySelector('.js-setsugetsuka-main-bg')
    const bgSmoothScroll = () => {
      const positionTop = positionBase.getBoundingClientRect().top
      const positionBottom = positionBase.getBoundingClientRect().bottom
      const scrollAmount = ((window.innerHeight - positionTop) / (positionBottom - positionTop + window.innerHeight)) * -48
      target.style.backgroundPositionY = `${scrollAmount}vw`
    }
    window.addEventListener('scroll', bgSmoothScroll)
    window.addEventListener('resize', bgSmoothScroll)
    bgSmoothScroll()
  }
})
