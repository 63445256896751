document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-admin-creator-payment-list')) {
    const showTotalPrice = ()=> {
      let total = 0;
      let checked = document.querySelectorAll('.payment_ids:checked');
      checked.forEach((element) => total = total + parseInt(element.dataset.price));
      document.querySelector('.js-creator-total-fee-price').textContent = `振込額合計： ¥ ${total.toLocaleString()}`
      document.querySelector('.js-creator-total-count').textContent = `件数： ${checked.length}`
    }

    document.querySelectorAll('.payment_ids').forEach((element) => {
      element.addEventListener('change', showTotalPrice)
    })
    document.querySelector('#total_payment_ids').addEventListener('change', showTotalPrice)
    showTotalPrice()
  }
})
