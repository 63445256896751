/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(function() {
  if ($('#js-bill_setting_form-b_or_c_flag_check').is('*')) {
    requireCheck();
    disableOtherForm();
    changeDeleteFlag();

    $('#js-bill_setting_form-b_or_c_flag_check input[name*="b_or_c_flag"]:radio').on('click',  () => requireCheck());

    $('.js-industry-other').on('change', function() {
      disableOtherForm();
      return changeDeleteFlag();
    });
  }

  if ($('.js-not-have-url').is('*')) {
    disableCompanyUrl();

    return $('.js-not-have-url').on('change', () => disableCompanyUrl());
  }
});

var requireCheck = function() {
  const b_or_c_flag = parseInt($('#js-bill_setting_form-b_or_c_flag_check input:radio[name*="b_or_c_flag"]:checked').val());

  if (b_or_c_flag === 0) {
    // 企業の場合
    $('.js-bill_setting_form-business').css('display', '');
    return $('.js-bill_setting_form-personal').css('display', 'none');
  } else {
    // 個人の場合
    $('.js-bill_setting_form-business').css('display', 'none');
    return $('.js-bill_setting_form-personal').css('display', '');
  }
};

var disableOtherForm = function() {
  if ($('.js-industry-other').prop('checked') === true) {
    return $('.js-industry-other-input').prop('disabled', false);
  } else {
    return $('.js-industry-other-input').prop('disabled', true);
  }
};

var changeDeleteFlag = function() {
  if ($('.js-industry-other').prop('checked') === true) {
    return $('.js-industry-other-delete-checkbox').prop('checked', false);
  } else {
    return $('.js-industry-other-delete-checkbox').prop('checked', true);
  }
};

var disableCompanyUrl = function() {
  if ($('.js-not-have-url').prop('checked') === true) {
    return $('.js-company-url').prop('disabled', true);
  } else {
    return $('.js-company-url').prop('disabled', false);
  }
};
