document.addEventListener('DOMContentLoaded', () => {
  const targetPage = document.querySelector('.js-mrc2023-first-result')

  if (targetPage) {
    // モーダル開閉時に URL を修正
    const baseUrl = targetPage.dataset.baseUrl
    const modals = document.querySelectorAll('.js-mrc2023-first-result-passed-category-audio-list-item-modal')
    modals.forEach((modal) => {
      $(modal).on('show.bs.modal', (e) => {
        history.replaceState('', '', e.target.dataset.url)
      })
      $(modal).on('hide.bs.modal', () => {
        history.replaceState('', '', baseUrl)
      })
    })


    // id 付きの URL でアクセスした場合
    const urlMatch = location.pathname.match(/first_results\/(J?\d+)/)
    if (urlMatch) {
      const id = urlMatch[1]
      const targetModalLink = document.querySelector(`.js-mrc2023-first-result-passed-category-audio-list-item-info-title-link-${id}`)

      if (targetModalLink) {
        const passedNav = document.querySelector('.js-mrc2023-first-result-passed-nav')
        const offset = 16

        window.scrollTo({
          top: targetModalLink.getBoundingClientRect().top + window.scrollY - passedNav.clientHeight - offset,
          behavior: 'smooth'
        })
        targetModalLink.click()
      }
    }


    // ボイスバンクの表示・非表示を切り替えるチェックボックス
    const voiceBanksToggleCheckBoxes = document.querySelectorAll('.js-mrc2023-first-result-passed-category-voice-banks-toggle-check-box')
    const refreshVoiceBanksToggleCheckBox = (targetCheckBox) => {
      const targetCategory = targetCheckBox.closest('.js-mrc2023-first-result-passed-category')
      const targetClassName = 'mrc2023-first-result-passed-category-show-voice-banks'

      if (targetCheckBox.checked) {
        targetCategory.classList.add(targetClassName)
      } else {
        targetCategory.classList.remove(targetClassName)
      }
    }
    voiceBanksToggleCheckBoxes.forEach((voiceBanksToggleCheckBox) => {
      voiceBanksToggleCheckBox.addEventListener('change', () => refreshVoiceBanksToggleCheckBox(voiceBanksToggleCheckBox))
      refreshVoiceBanksToggleCheckBox(voiceBanksToggleCheckBox)
    })



    // 各部門のナビゲーション制御
    const passedNav = document.querySelector('.js-mrc2023-first-result-passed-nav')
    const passedNavLinkA = document.querySelector('.js-mrc2023-first-result-passed-nav-link-a')
    const passedNavLinkB = document.querySelector('.js-mrc2023-first-result-passed-nav-link-b')
    const passedCategoryA = document.querySelector('.js-mrc2023-first-result-passed-category-a')
    const passedCategoryB = document.querySelector('.js-mrc2023-first-result-passed-category-b')

    let visibilityHiddenTimeoutId
    const showPassedNav = () => {
      if (passedNav.dataset.active === 'true') return

      clearTimeout(visibilityHiddenTimeoutId)
      passedNav.style.visibility = 'visible'
      passedNav.dataset.active = 'true'
    }
    const hidePassedNav = () => {
      if (passedNav.dataset.active === 'false') return

      const transitionDuration = parseFloat(getComputedStyle(passedNav).transitionDuration)
      passedNav.dataset.active = 'false'
      visibilityHiddenTimeoutId = setTimeout(() => passedNav.style.visibility = 'hidden', transitionDuration * 1000)
    }

    const handlePassedNav = () => {
      const passedCategoryATopPosition    = parseInt(window.scrollY + passedCategoryA.getBoundingClientRect().top)
      const passedCategoryABottomPosition = parseInt(window.scrollY + passedCategoryA.getBoundingClientRect().bottom)
      const passedCategoryBTopPosition    = parseInt(window.scrollY + passedCategoryB.getBoundingClientRect().top)
      const passedCategoryBBottomPosition = parseInt(window.scrollY + passedCategoryB.getBoundingClientRect().bottom)

      if (passedCategoryATopPosition <= window.scrollY && window.scrollY < passedCategoryABottomPosition) {
        showPassedNav()
        passedNavLinkA.dataset.active = 'true'
        passedNavLinkB.dataset.active = 'false'
      } else if (passedCategoryBTopPosition <= window.scrollY && window.scrollY < passedCategoryBBottomPosition) {
        showPassedNav()
        passedNavLinkA.dataset.active = 'false'
        passedNavLinkB.dataset.active = 'true'
      } else {
        hidePassedNav()
        passedNavLinkA.dataset.active = 'false'
        passedNavLinkB.dataset.active = 'false'
      }
    }
    document.addEventListener('scroll', handlePassedNav)
    document.addEventListener('resize', handlePassedNav)
    handlePassedNav()
  }


  // ランダムで聴いてみるボタン
  const randomBtns = document.querySelectorAll('.js-mrc2023-first-result-passed-category-random-btn')
  randomBtns.forEach((randomBtn) => {
    randomBtn.addEventListener('click', () => {
      const randomAudioIds = JSON.parse(randomBtn.dataset.randomAudioIds)
      const id = randomAudioIds[Math.floor(Math.random() * randomAudioIds.length)]
      const targetModalLink = document.querySelector(`.js-mrc2023-first-result-passed-category-audio-list-item-info-title-link-${id}`)

      if (targetModalLink) {
        const passedNav = document.querySelector('.js-mrc2023-first-result-passed-nav')
        const offset = 16

        window.scrollTo({
          top: targetModalLink.getBoundingClientRect().top + window.scrollY - passedNav.clientHeight - offset,
          behavior: 'smooth'
        })
        targetModalLink.click()
      }
    })
  })
})
