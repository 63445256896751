$(function() {
  if ($('.js-advanced-search-bar').is('*')) {
    // 検索条件の選択中にポップアップを閉じない
    $('.js-advanced-search-bar .js-dropdown-menu').on('click', function (e) {
      e.stopPropagation();
    });

    // タグ入力フィールド削除ボタンの制御
    const toggleRemoveButton = function () {
      const $targetFields = $('.js-advanced-search-bar .js-remove-tag-field');
      const $targetButton = $targetFields.find('a');
      if ($targetFields.length <= 1) {
        $targetButton.removeClass('text-danger');
        $targetButton.addClass('text-body-secondary');
        $targetButton.css('opacity', .5);
      } else {
        $targetButton.addClass('text-danger');
        $targetButton.removeClass('text-body-secondary');
        $targetButton.css('opacity', 1);
      }
    };
    toggleRemoveButton();

    // タグ入力フィールド削除ボタン
    $('.js-advanced-search-bar .js-remove-tag-field').on('click', function (e) {
      if ($('.js-advanced-search-bar .js-tag-field-group').length > 1) {
        $(this).closest('.js-tag-field-group').remove();
      }
      toggleRemoveButton();
      e.preventDefault();
      return false
    });

    // タグ入力フィールド追加ボタン
    $('.js-advanced-search-bar .js-add-tag-field').on('click', function (e) {
      const newTagFieldGroup = $('.js-advanced-search-bar .js-tag-field-group:last').clone(true);
      newTagFieldGroup.find('input').val('');
      $(this).before(newTagFieldGroup);
      toggleRemoveButton();
      e.preventDefault();
      return false
    });

    // スマホ表示時の「すべて表示」を制御
    $('.js-advanced-search-bar-open-btn, .js-advanced-search-bar .dropdown-toggle').on('click', function (e) {
      const $button = $('.js-advanced-search-bar-open-btn');
      if ($button.is('*')) {
        $('.js-advanced-search-bar-head').addClass('advanced-search-bar-head-show');
      }
      $button.remove();
      e.preventDefault();
    });
  }
});
