document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-s-plan-reward-distributions')) {
    const showInfoForDevelopersRadios = document.querySelectorAll('.js-show-info-for-developers-radio')
    const infoForDevelopers = document.querySelectorAll('.js-info-for-developers')

    const updateInfoForDevelopers = () => {
      const show = document.querySelector('.js-show-info-for-developers-radio:checked').value === 'true'
      infoForDevelopers.forEach((info) => {
        info.style.display = show ? '' : 'none'
      })
    }
    showInfoForDevelopersRadios.forEach((radio) => radio.addEventListener('change', updateInfoForDevelopers))
    updateInfoForDevelopers()
  }
})
