document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-fukubukuro-202303')) {
    const positionBase = document.querySelector('.js-fukubukuro-202303-main')
    const target = document.querySelector('.js-fukubukuro-202303-main-bg')
    const bgSmoothScroll = () => {
      const positionTop = positionBase.getBoundingClientRect().top
      const positionBottom = positionBase.getBoundingClientRect().bottom
      const scrollPercentage = ((window.innerHeight - positionTop) / (positionBottom - positionTop + window.innerHeight)) * 100
      target.style.backgroundPositionY = `${scrollPercentage}%`
    }
    window.addEventListener('scroll', bgSmoothScroll)
    window.addEventListener('resize', bgSmoothScroll)
    bgSmoothScroll()
  }
})
