/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
class AdvancedAudioSearch {
  static initClass() {
    this.DATA_KEY_FIELD_NAME = 'field-name';
    this.ADDITIONAL_SEARCH_CRITERIA_MULTIPLE_CSS_CLASS = 'advanced-search-multiple';

    this.SELECTOR_JS_TEMPLATE = '.js-template';
    this.SELECTOR_SEARCH_CRITERION = '.js-advanced-search-criterion';
    this.SELECTOR_DEPEND_ON_SELECTED_FIELD = '.js-advanced-search-criterion-depend-on-selected-field';
    this.SELECTOR_VALUE_ADDITIONAL = '.js-advanced-search-criterion-value-additional';
    this.SELECTOR_SEARCH_CRITERION_REMOVE_BUTTON = '.js-advanced-search-delete-inner';
    this.SELECTOR_FIELD_NAME_SELECT_BOX = 'select[name="field_name"]';
    this.SELECTOR_AUDIO_CATEGORY = 'input[name="audio_category"]';
    this.SELECTOR_SALE_FILE_TYPES = 'input[name="sale_file_type"]';
    this.SELECTOR_YOUTUBE_SAFETY = 'input[name="youtube_safety"]';
    this.SELECTOR_SUBSCRIPTION = 'input[name="subscription"]';
    this.SELECTOR_TOOLTIP = '[data-bs-toggle="tooltip"]';

    this.UPDATE_HIT_COUNT_DELAY = 500; // milli second

    this.addSearchCriterionTarget = null;
    this.searchCriterionTemplate = null;
    this.dependOnSelectedFieldTemplates = {};
    this.additionalTemplates = {};
    this.page = null;

    this.updateHitCountTimerID = null;
  }

  static initialize() {
    AdvancedAudioSearch.addSearchCriterionTarget = $('#add-search-criterion-target');
    if (AdvancedAudioSearch.addSearchCriterionTarget.length !== 1) {
      return;
    }

    AdvancedAudioSearch.searchCriterionTemplate = $(
      AdvancedAudioSearch.SELECTOR_JS_TEMPLATE + AdvancedAudioSearch.SELECTOR_SEARCH_CRITERION
    );
    AdvancedAudioSearch.cleanTemplate(AdvancedAudioSearch.searchCriterionTemplate);

    AdvancedAudioSearch.addTemplates(
      AdvancedAudioSearch.SELECTOR_JS_TEMPLATE + AdvancedAudioSearch.SELECTOR_DEPEND_ON_SELECTED_FIELD,
      AdvancedAudioSearch.dependOnSelectedFieldTemplates
    );
    AdvancedAudioSearch.addTemplates(
      AdvancedAudioSearch.SELECTOR_JS_TEMPLATE + AdvancedAudioSearch.SELECTOR_VALUE_ADDITIONAL,
      AdvancedAudioSearch.additionalTemplates
    );

    AdvancedAudioSearch.page = $('input[name="page"]');

    $(AdvancedAudioSearch.SELECTOR_AUDIO_CATEGORY).change(() => AdvancedAudioSearch.updateHitCount());

    $(AdvancedAudioSearch.SELECTOR_SALE_FILE_TYPES).change(() => AdvancedAudioSearch.updateHitCount());

    $(AdvancedAudioSearch.SELECTOR_YOUTUBE_SAFETY).change(() => AdvancedAudioSearch.updateHitCount());

    $(AdvancedAudioSearch.SELECTOR_SUBSCRIPTION).change(() => AdvancedAudioSearch.updateHitCount());

    return $(AdvancedAudioSearch.SELECTOR_SEARCH_CRITERION).each(function() {
      return new SearchCriterion($(this));
    });
  }

  static addTemplates(selector, targetObject) {
    return $(selector).each(function() {
      const template = $(this);
      AdvancedAudioSearch.cleanTemplate(template);
      return targetObject[template.data(AdvancedAudioSearch.DATA_KEY_FIELD_NAME)] = template;
    });
  }

  static cleanTemplate(template) {
    template.remove();
    template.removeClass('d-none');
    return template.removeClass(AdvancedAudioSearch.SELECTOR_JS_TEMPLATE);
  }

  static addSearchCriterion() {
    const clonedSearchCriterion = AdvancedAudioSearch.searchCriterionTemplate.clone();
    clonedSearchCriterion.appendTo(AdvancedAudioSearch.addSearchCriterionTarget);
    new SearchCriterion($(clonedSearchCriterion));
    return AdvancedAudioSearch.initializeTooltip(clonedSearchCriterion);
  }

  static pageKeyDownHandler(event) {
    if(event.keyCode === 13) {
      return AdvancedAudioSearch.request({page: AdvancedAudioSearch.pageVal()});
    }
  }

  static pageVal() {
    return parseInt(AdvancedAudioSearch.page.val());
  }

  static valueOrComparisonOperatorChangeHandler(parentJqueryObject) {
    $('input[type="checkbox"],input[type="radio"],select', parentJqueryObject).change(() => AdvancedAudioSearch.updateHitCount());
    const textOrNumberInput = $('input[type="text"],input[type="number"]', parentJqueryObject);
    textOrNumberInput.keyup(() => AdvancedAudioSearch.updateHitCount());
    return textOrNumberInput.on('paste', () => AdvancedAudioSearch.updateHitCount());
  }

  static initializeTooltip(target) {
    return $(AdvancedAudioSearch.SELECTOR_TOOLTIP, target).tooltip();
  }

  static enterSubmitHandler(parentJqueryObject) {
    const inputArea = $('input[type="checkbox"],input[type="radio"],select,input[type="text"],input[type="number"]', parentJqueryObject);
    let keyDownCode = null;
    inputArea.keydown(event => keyDownCode = event.which);

    return inputArea.keyup(function(event) {
      if ((event.which === 13) && (event.which === keyDownCode)) {
        return AdvancedAudioSearch.request({page: 0});
      }
    });
  }

  static requestParams(assignParams) {
    if (assignParams == null) { assignParams = {}; }
    const audioCategories = [];
    let saleFileTypes = null;
    let youtubeSafety = null;
    $(AdvancedAudioSearch.SELECTOR_AUDIO_CATEGORY + ':checked').each(function() {
      return audioCategories.push($(this).val());
    });
    $(AdvancedAudioSearch.SELECTOR_SALE_FILE_TYPES + ':checked').each(function() {
      return saleFileTypes = $(this).val();
    });
    $(AdvancedAudioSearch.SELECTOR_YOUTUBE_SAFETY + ':checked').each(function() {
      return youtubeSafety = $(this).val();
    });
    const subscription_type = $(AdvancedAudioSearch.SELECTOR_SUBSCRIPTION + ':checked').val();

    const searchCriteria = [];
    $(AdvancedAudioSearch.SELECTOR_SEARCH_CRITERION).each(function() {
      const fieldName = $(AdvancedAudioSearch.SELECTOR_FIELD_NAME_SELECT_BOX, this).val();
      const values = [];
      $('input[name="value"]', this).each(function() {
        return values.push($(this).val());
      });
      $('select[name="value"]', this).each(function() {
        return values.push($(this).val());
      });
      const comparisonOperator = $('select[name="comparison_operator"]', this).val();
      return searchCriteria.push({fn: fieldName, v: values, co: comparisonOperator});
    });

    let sort = $('input[name="sort"]').val();
    let size = $('input[name="size"]').val();
    let page = AdvancedAudioSearch.pageVal();

    // 先生...Object.assign()が使いたいです。fkin ie
    if (assignParams.hasOwnProperty('sort')) {
      sort = assignParams['sort'];
    }
    if (assignParams.hasOwnProperty('size')) {
      size = assignParams['size'];
    }
    if (assignParams.hasOwnProperty('page')) {
      page = assignParams['page'];
    }

    return {ac: audioCategories, q: searchCriteria, sort, size, page, sft: saleFileTypes, ytb: youtubeSafety, sbs: subscription_type};
  }

  static updateHitCount(assignParams) {
    if (assignParams == null) { assignParams = {}; }
    if (AdvancedAudioSearch.updateHitCountTimerID !== null) {
      clearTimeout(AdvancedAudioSearch.updateHitCountTimerID);
      AdvancedAudioSearch.updateHitCountTimerID = null;
    }

    return AdvancedAudioSearch.updateHitCountTimerID = setTimeout(
      function() {
        jQuery.get(
          '/advanced_audio_search/count',
          AdvancedAudioSearch.requestParams(assignParams),
          function(data) {
            const separatedCount = data.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,');
            return $('.js-hit-count').text(separatedCount);
        });
        return AdvancedAudioSearch.updateHitCountTimerID = null;
      }
      ,
      AdvancedAudioSearch.UPDATE_HIT_COUNT_DELAY
    );
  }

  static request(assignParams) {
    if (assignParams == null) { assignParams = {}; }
    return location.href = '/advanced_audio_search?' + $.param(AdvancedAudioSearch.requestParams(assignParams));
  }
}

class SearchCriterion {

  constructor(searchCriterion) {
    this.searchCriterion = searchCriterion;
    this.fieldNameSelectBox = $(AdvancedAudioSearch.SELECTOR_FIELD_NAME_SELECT_BOX, this.searchCriterion);
    this.removeButton = $(AdvancedAudioSearch.SELECTOR_SEARCH_CRITERION_REMOVE_BUTTON + ' a', this.searchCriterion);
    this.dependOnSelectedField = new DependOnSelectedField(
      this.getFieldName(),
      $(AdvancedAudioSearch.SELECTOR_DEPEND_ON_SELECTED_FIELD, this.searchCriterion)
    );

    this.changeFieldHandler();
    this.removeHandler();
    this.syncRemoveButton();
  }

  getFieldName() {
    return this.fieldNameSelectBox.val();
  }

  changeFieldHandler() {
    const searchCriterion = this;
    return this.fieldNameSelectBox.change(function() {
      const fieldName = searchCriterion.getFieldName();
      const template = AdvancedAudioSearch.dependOnSelectedFieldTemplates[fieldName];
      const dependOnSelectedField = template.clone();
      searchCriterion.dependOnSelectedField.dependOnSelectedField.replaceWith(dependOnSelectedField);
      searchCriterion.dependOnSelectedField = new DependOnSelectedField(fieldName, dependOnSelectedField);
      AdvancedAudioSearch.updateHitCount();
      return AdvancedAudioSearch.initializeTooltip(dependOnSelectedField);
    });
  }

  removeHandler() {
    const searchCriterion = this;
    return this.removeButton.click(function() {
      searchCriterion.searchCriterion.remove();
      searchCriterion.syncRemoveButton();
      return AdvancedAudioSearch.updateHitCount();
    });
  }

  syncRemoveButton() {
    if ($(AdvancedAudioSearch.SELECTOR_SEARCH_CRITERION, AdvancedAudioSearch.addSearchCriterionTarget).length === 1) {
      return $(AdvancedAudioSearch.SELECTOR_SEARCH_CRITERION_REMOVE_BUTTON, AdvancedAudioSearch.addSearchCriterionTarget).hide();
    } else {
      return $(AdvancedAudioSearch.SELECTOR_SEARCH_CRITERION_REMOVE_BUTTON, AdvancedAudioSearch.addSearchCriterionTarget).show();
    }
  }
}

class DependOnSelectedField {
  constructor(fieldName, dependOnSelectedField) {
    this.fieldName = fieldName;
    this.dependOnSelectedField = dependOnSelectedField;
    this.addOrCriterionButton = $('.js-add-or-search-criterion-button', this.dependOnSelectedField);
    this.advancedSearchInputGroup = $('.js-advanced-search-input-group', this.dependOnSelectedField);
    this.addOrCriterionTarget = $('.js-add-or-search-criterion-target', this.dependOnSelectedField);

    const dependOnSelectedFieldObject = this;
    $(AdvancedAudioSearch.SELECTOR_VALUE_ADDITIONAL, this.addOrCriterionTarget).each(function() {
      return dependOnSelectedFieldObject.addOrCriterion($(this));
    });

    this.addOrCriterionHandler();
    AdvancedAudioSearch.valueOrComparisonOperatorChangeHandler(dependOnSelectedField);
    AdvancedAudioSearch.enterSubmitHandler(dependOnSelectedField);
  }

  addOrCriterion(orCriterion) {
    new AdditionalSearchCriterion(orCriterion);
    this.syncAddOrCriterionTarget();
    const dependOnSelectedField = this;
    return orCriterion.on("remove", () => dependOnSelectedField.syncAddOrCriterionTarget(2));
  }

  addOrCriterionHandler() {
    const dependOnSelectedField = this;
    return this.addOrCriterionButton.click(function() {
      const template = AdvancedAudioSearch.additionalTemplates[dependOnSelectedField.fieldName];
      const orCriterion = template.clone();
      orCriterion.appendTo(dependOnSelectedField.addOrCriterionTarget);
      dependOnSelectedField.addOrCriterion(orCriterion);
      AdvancedAudioSearch.updateHitCount();
      return AdvancedAudioSearch.initializeTooltip(orCriterion);
    });
  }

  syncAddOrCriterionTarget(threshold) {
    if (threshold == null) { threshold = 1; }
    if (this.addOrCriterionTarget.children().length === threshold) {
      return this.advancedSearchInputGroup.removeClass(AdvancedAudioSearch.ADDITIONAL_SEARCH_CRITERIA_MULTIPLE_CSS_CLASS);
    } else {
      return this.advancedSearchInputGroup.addClass(AdvancedAudioSearch.ADDITIONAL_SEARCH_CRITERIA_MULTIPLE_CSS_CLASS);
    }
  }
}

class AdditionalSearchCriterion {
  constructor(additionalSearchCriterion) {
    this.additionalSearchCriterion = additionalSearchCriterion;
    this.removeButton = $('.js-advanced-search-delete-btn a', this.additionalSearchCriterion);
    this.addRemoveHandler();
    AdvancedAudioSearch.valueOrComparisonOperatorChangeHandler(additionalSearchCriterion);
    AdvancedAudioSearch.enterSubmitHandler(additionalSearchCriterion);
  }

  addRemoveHandler() {
    const {
      additionalSearchCriterion
    } = this;
    return this.removeButton.click(function() {
      additionalSearchCriterion.remove();
      return AdvancedAudioSearch.updateHitCount();
    });
  }
}

$(() => {
  window.AdvancedAudioSearch = AdvancedAudioSearch
  window.AdvancedAudioSearch.initClass()
  window.AdvancedAudioSearch.initialize()
});
