/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(function() {
  if ($('.js-target-size').is('*')) {
    const parent_target_resize = () => $('.js-target-size').each(function(index, element) {
      const target = $(element).find('.js-target-size-is-maximum-size');
      target.css({ height: '100%', width: '100%' });
      const max = Math.floor($(element).width());
      target.css({ height: max, width: max });
      return target.children('img').css({ maxHeight: max, maxWidth: max });
    });

    parent_target_resize();
    return $(window).on('resize', () => parent_target_resize());
  }
});
