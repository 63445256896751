function toggleCardForm() {
  var $cardTypeRegistered = $('#card-type-registered');
  var $cardTypeNew = $('#card-type-new');
  if ($('input[name=card_type]:checked').val() === 'registered') {
    $cardTypeRegistered.show();
    $cardTypeNew.hide();
  } else {
    $cardTypeRegistered.hide();
    $cardTypeNew.show();
  }
}

function selectCardTypeNew() {
  $('#card_type_new').prop('checked', true).change()
}

window.selectCardTypeNew = selectCardTypeNew

$(function() {
  if ($('#card-type-registered').is('*')) {
    $('input[name=card_type]').on('change', toggleCardForm);
    toggleCardForm();
  }
});
