/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(function() {
  if ($('#js-keyword-form').is('*')) {
    let keyword, keyword_button;
    const parent_form = $('#js-keyword-form');
    const keyword_buttons        = parent_form.find('.js-keyword-form_keyword-item');
    const selected_keywords_list = parent_form.find('.js-keyword-form_selected-item-list');
    const keyword_close_buttons  = parent_form.find('.js-keyword-form_selected-item-list li button.btn-close');
    const keyword_input_field    = parent_form.find('.js-keyword-form_input-item');
    const keyword_append_button  = parent_form.find('.js-keyword-form_append-item');
    const limit_text             = parent_form.find('.js-keyword-form_keyword-limit');
    const selected_keywords      = () => convertKeywordsArray(selected_keywords_list);

    // 初期化
    selected_keywords_list.sortable();
    refreshKeywordlimit(selected_keywords().length, limit_text);
    if (selected_keywords().length !== 0) {
      for (keyword of Array.from(selected_keywords())) {
        for (keyword_button of Array.from(keyword_buttons)) {
          if (keyword_button.innerText === keyword) { changeKeywordSelected($(keyword_button)); }
        }
      }
    }

    // テキストエリアの高さを自動調整
    const keywords_textarea = $('#keywords_textarea');
    const keywords_textarea_lineheight = parseInt(keywords_textarea.css('lineHeight'));
    keywords_textarea.on('input', function(e) {
      const lines = ($(this).val() + '\n').match(/\n/g).length;
      return $(this).height(keywords_textarea_lineheight * lines);
    });

    // タグ選択ボタン押下時
    $(document).on('click', '#js-keyword-form .js-keyword-form_keyword-item', function() {
      keyword = $(this).val();
      keyword_button = $(`button[value='${keyword}']`);
      if (keyword_button.attr('aria-selected') === 'true') {
        return removeKeyword(keyword, selected_keywords(), selected_keywords_list, keyword_button, limit_text);
      } else {
        return addKeyword(keyword, selected_keywords(), selected_keywords_list, keyword_button, limit_text);
      }
    });

    // タグの×ボタン押下時
    $(document).on('click', '#js-keyword-form .js-keyword-form_selected-item-list li button.btn-close', function() {
      const keyword_close_button = $(this);
      keyword = keyword_close_button.parent().find('.tag-normal-selected').text().trim();
      keyword_button = $(`button[value='${keyword}']`);
      return removeKeyword(keyword, selected_keywords(), selected_keywords_list, keyword_button, limit_text);
    });

    // タグ追加ボタン押下時
    keyword_append_button.on('click', function() {
      const new_keywords = keyword_input_field.val().trim();
      if (!(new_keywords.length > 0)) { return false; }

      const new_keywords_array = new_keywords.split('\n');
      if (new_keywords_array.filter(keyword => keyword.length > 50).length > 0) {
        alert('長すぎるタグがあります。1つのタグには最大50文字まで入力可能です。');
        return false;
      }

      for (var new_keyword of Array.from(new_keywords_array)) {
        new_keyword = new_keyword.trim();
        if (new_keyword.length > 0) {
          if ($.inArray(new_keyword, selected_keywords()) < 0) {
            keyword_button = $(`button[value='${replaceKeyword(new_keyword)}']`);
            addKeyword(new_keyword, selected_keywords(), selected_keywords_list, keyword_button, limit_text);
          }
        }
      }
      keyword_input_field.val('');
      return keywords_textarea.height(keywords_textarea_lineheight);
    });

    // Enterキー押下時
    keyword_input_field.on('keypress', function(e) {
      if ((e.keyCode === 13) && !e.shiftKey) {
        e.preventDefault();
        return keyword_append_button.click();
      }
    });

    // タグのコピペ時に改行区切りにする
    return $('.js-keyword-form_selected-item-list').on('copy', function(e) {
      e.preventDefault();

      const selection = window.getSelection();
      const dom = selection.getRangeAt(0).cloneContents();
      const items = dom.querySelectorAll('li');
      const keywordsArray = (Array.from(items).map((item) => item.getAttribute('data-keyword')));
      const copyText = keywordsArray.join('\n');

      const userAgent = window.navigator.userAgent.toLowerCase();
      if (userAgent.match(/msie|trident/)) {
        return window.clipboardData.setData('Text', copyText); // IE
      } else {
        return e.originalEvent.clipboardData.setData('text/plain', copyText);
      }
    });
  }
}); // IE以外

var addKeyword = function(keyword, selected_keywords, selected_keywords_list, keyword_button, limit_text) {
  if (($.inArray(keyword, selected_keywords) > -1) || (limit_text.text() <= 0)) { return false; }

  selected_keywords_list.append(generateKeywordElement(keyword));
  refreshSortable();
  refreshKeywordlimit(selected_keywords.length + 1, limit_text);
  if (keyword_button) { return changeKeywordSelected(keyword_button); }
};

var removeKeyword = function(keyword, selected_keywords, selected_keywords_list, keyword_button, limit_text) {
  $(`li[data-keyword='${keyword}']`).remove();
  refreshKeywordlimit(selected_keywords.length - 1, limit_text);
  if (keyword_button) { return changeKeywordUnselected(keyword_button); }
};

var convertKeywordsArray = function(selected_keywords_list) {
  const elements = selected_keywords_list.find('li');
  return Array.from(elements).map((element) => element.textContent.trim());
};

var changeKeywordSelected = function(keyword_button) {
  keyword_button.attr('aria-selected', 'true');
  keyword_button.removeClass('btn-outline-primary');
  return keyword_button.addClass('btn-primary');
};

var changeKeywordUnselected = function(keyword_button) {
  keyword_button.attr('aria-selected', 'false');
  keyword_button.removeClass('btn-primary');
  return keyword_button.addClass('btn-outline-primary');
};

var refreshKeywordlimit = function(keywords_count, target) {
  const keyword_limit = $('#js-keyword-form').data('keyword-limit');
  target.text(keyword_limit - keywords_count);
  if ((keyword_limit - keywords_count) > 0) {
    target.parent().show();
    return target.parent().next('.text-danger').hide();
  } else {
    target.parent().hide();
    return target.parent().next('.text-danger').show();
  }
};

var generateKeywordElement = function(keyword) {
  const li = document.createElement('li');
  li.setAttribute('data-keyword', keyword);

  const div = document.createElement('div');
  div.setAttribute('class', 'btn tag-normal-selected tag-normal-with-close');
  div.textContent = keyword;
  li.appendChild(div);

  const input = document.createElement( "input" );
  input.setAttribute( "type", "hidden" );

  const name_prefix = $('#js-keyword-form').data('name-prefix');
  input.setAttribute( "name", name_prefix + "[keywords][]" );

  input.setAttribute( "value", keyword );
  li.appendChild(input);

  const button = document.createElement('button');
  button.setAttribute('class', 'btn-close');
  button.setAttribute('type', 'button');
  li.appendChild(button);
  return li;
};

var replaceKeyword = keyword => keyword.replace(/'/g, "\\'");

var refreshSortable = () => $('#js-keyword-form .js-keyword-form_selected-item-list').sortable('refresh');
