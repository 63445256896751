/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(function() {
  if ($('.js-zip-code').is('*')) {
    return $('.js-set-address').click(function() {
      $('.js-zip-code-error').css('display', 'none');
      $.ajax({
        type : 'get',
        url : '/account/get_address_from_zip_code',
        data : {
          address : $('.js-zip-code').val(),
        },
        success(res) {
          if (res.status === "OK") {
            const obj = res.results[0].address_components;

            const val_1 = obj[obj.length - 1];
            if (val_1 !== undefined) {
              $('.js-country').val(val_1['long_name']);
            }

            const val_2 = obj[obj.length - 2];
            if (val_2 !== undefined) {
              $('.js-prefecture').val(val_2['long_name']);
            }

            const val_3 = obj[obj.length - 3];
            if (val_3 !== undefined) {
              $('.js-address').val(val_3['long_name']);
            }

            const val_4 = obj[obj.length - 4];
            if (val_4 !== undefined) {
              return $('.js-address2').val(val_4['long_name']);
            }

          } else {
            return $('.js-zip-code-error').css('display', 'block');
          }
        }
      });
      return false;
    });
  }
});
