document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-mrc-2022s')) {
    const scrollFadeIn = () => {
      document.querySelectorAll('.fade-in:not(.scroll-in)').forEach((fadeInTarget) => {
        const scrollOffSet = window.innerHeight * 0.08
        const rect = fadeInTarget.getBoundingClientRect().top
        const scroll = document.documentElement.scrollTop
        const offset = rect + scroll
        const windowHeight = window.innerHeight

        if (scroll > offset - windowHeight + scrollOffSet) {
          fadeInTarget.classList.add('scroll-in')
        }
      })
    }

    window.addEventListener('scroll', scrollFadeIn)
    window.addEventListener('resize', scrollFadeIn)
    scrollFadeIn()
  }
})
