document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-scroll-fade-in')) {
    const scrollFadeIn = () => {
      document.querySelectorAll('.js-fade-in:not(.js-scroll-in)').forEach((fadeInTarget) => {
        const scrollOffSet = window.innerHeight * 0.06
        const rect = fadeInTarget.getBoundingClientRect().top
        const scroll = document.documentElement.scrollTop
        const offset = rect + scroll
        const windowHeight = window.innerHeight

        if (scroll > offset - windowHeight + scrollOffSet) {
          fadeInTarget.classList.add('js-scroll-in')
        }
      })
    }

    window.addEventListener('scroll', scrollFadeIn)
    window.addEventListener('resize', scrollFadeIn)
    scrollFadeIn()
  }
})
