// コピーを実行するボタンに .js-text-copy クラスを付与し、data-target にコピー対象のセレクタを設定してください。
// 例)
//   <button class="js-text-copy" data-target="#target">
//   <input id="target" value="target text">

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js-text-copy').forEach((textCopyBtn) => {
    textCopyBtn.addEventListener('click', (e) => {
      const element = e.target
      const targetSelector = element.dataset.target
      const target = document.querySelector(targetSelector)

      if (target) {
        navigator.clipboard.writeText(target.value)

        // ツールチップを表示
        element.dataset.bsToggle = 'tooltip'
        element.dataset.bsTitle = 'コピーしました'
        element.dataset.bsTrigger = 'focus'
        element.dataset.bsPlacement = 'bottom'
        element.dataset.bsOffset = '0, 4'
        $(element).tooltip('show')

        // 非表示になったらツールチップを削除
        $(element).on('hidden.bs.tooltip', () => {
          $(element).tooltip('dispose')
        })
      }
    })
  })
})
