/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(function() {
  if ($('#category_form_use_meta_title').is('*')) {
    toggleMetaTitleEnable();
    document.getElementById('category_form_use_meta_title').onchange = toggleMetaTitleEnable;
  }
  if ($('#category_form_use_meta_description').is('*')) {
    toggleMetaDescriptionEnable();
    document.getElementById('category_form_use_meta_description').onchange = toggleMetaDescriptionEnable;
  }
});

var toggleMetaTitleEnable = function() {
  const enable = !document.getElementById('category_form_use_meta_title').checked;
  return document.getElementById('category_form_meta_title').disabled = enable;
};

var toggleMetaDescriptionEnable = function() {
  const enable = !document.getElementById('category_form_use_meta_description').checked;
  return document.getElementById('category_form_meta_description').disabled = enable;
};
