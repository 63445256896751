// submit 前に HTML5 のバリデーションを行う
document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js-pre-check-validity').forEach((preCheckElement) => {
    preCheckElement.addEventListener('click', (e) => {
      const form = e.target.closest('form')
      if (!form.checkValidity()) {
        form.reportValidity()
        e.stopPropagation()
        e.preventDefault()
        return false
      }
    })

    // モーダルを表示するボタンだった場合
    if (preCheckElement.dataset.bsToggle === 'modal') {
      const modal = document.querySelector(preCheckElement.dataset.bsTarget)
      if (modal) {
        modal.addEventListener('show.bs.modal', (e) => {
          const form = e.target.closest('form')
          if (!form.checkValidity()) {
            form.reportValidity()
            e.stopPropagation()
            e.preventDefault()
            return false
          }
        })
      }
    }
  })
})
