document.addEventListener('DOMContentLoaded', () => {
  const modals = document.querySelectorAll('.js-purchase-confirmation-modal')

  if (modals.length > 0) {
    modals.forEach((modal) => {
      const form = modal.closest('form')

      // 確認モーダルを表示するボタンをクリックした際に、HTMLのバリデーションを発火させる
      modal.addEventListener('show.bs.modal', (e) => {
        if (!form.checkValidity()) {
          form.reportValidity()
          e.stopPropagation()
          e.preventDefault()
        }
      })

      // モーダルが表示されていない状態でサブミットされるのを禁止する（Enterキー等）
      // (なお、JavaScript のみで送信処理をしている場合は submitButton が存在しない)
      const submitButton = form.querySelector('button[type=submit]')
      if (submitButton) {
        submitButton.addEventListener('click', (e) => {
          if (!modal.classList.contains('show')) {
            e.stopPropagation()
            e.preventDefault()
          }
        })
      }
    })
  }
})
