$(() => {
  const $form = $('.js-original-album-entry-audios')

  if ($form.is('*')) {
    const $selectedAudioItemsWrapper = $('.js-original-album-entry-selected-audios')
    const maximumAudioCount = $form.data('maximum-audio-count')

    // モーダルの収録曲選択時にチェックボックスを反転
    const toggleAudioItemCheckbox = (target, bool) => {
      const $audioItem = $(target).parent().children('input[type=checkbox]')
      $audioItem.prop('checked', bool).change()
    }

    // モーダルの収録曲選択時にハイライト表示
    const $audioCheckbox = $('input[name=audio]')
    const toggleCheckbox = ($checkbox) => {
      const $audioItem = $checkbox.parents('.js-audio-item')
      if ($checkbox.is(':checked')) {
        $audioItem.addClass('player-audio-inner-selected')
      } else {
        $audioItem.removeClass('player-audio-inner-selected')
      }
    }
    $audioCheckbox.on('change', (e) => toggleCheckbox($(e.target)))

    // モーダルの収録曲選択時に選択された作品を追加
    $form.on('cocoon:after-insert', (_, $insertedItem, originalEvent) => {
      const $selectedAudio = $(originalEvent.target)
      $insertedItem.find('.js-selected-audio-name').html($selectedAudio.data('name'))
      $insertedItem.find('.js-selected-audio-id-field').val($selectedAudio.data('audio-id'))
      $insertedItem.find('.js-selected-audio-remove').attr('data-audio-id', $selectedAudio.data('audio-id'))
      refreshSelectedAudiosWrapper(true)
      refresh()
    })
    $form.on('cocoon:after-remove', () => refresh())

    // モーダルの収録曲選択時に最大曲数をチェック
    $form.on('cocoon:before-insert', (cocoonEvent, _, originalEvent) => {
      const $selectedAudio = $('.js-original-album-entry-selected-audio')
      if ($selectedAudio.length >= maximumAudioCount) {
        alert(`これ以上楽曲を選択できません。選択できるのは最大で${maximumAudioCount}曲までです。`)
        cocoonEvent.preventDefault()
      } else {
        toggleAudioItemCheckbox(originalEvent.target, true)
      }
    })

    // モーダルの収録曲選択を解除した時に選択された作品を除外
    const $audioItemRemoveButton = $('.js-audio-item-remove')
    $audioItemRemoveButton.on('click', (e) => {
      const audioId = $(e.target).data('audio-id')
      $(`.js-selected-audio-remove[data-audio-id=${audioId}]`).click()
      toggleAudioItemCheckbox(e.target, false)
    })

    // 選択された作品の順番を並べ替え
    $(document).on('click', '.js-selected-audio-change-sort-up', (e) => {
      const $parentItem = $(e.target).parents('.js-original-album-entry-selected-audio')
      const targetIndex = $('.js-original-album-entry-selected-audio:visible').index($parentItem)
      $parentItem.insertBefore($(`.js-original-album-entry-selected-audio:visible:eq(${targetIndex - 1})`))
      refresh()
      e.preventDefault()
      return false
    })
    $(document).on('click', '.js-selected-audio-change-sort-down', (e) => {
      const $parentItem = $(e.target).parents('.js-original-album-entry-selected-audio')
      const targetIndex = $('.js-original-album-entry-selected-audio:visible').index($parentItem)
      $parentItem.insertAfter($(`.js-original-album-entry-selected-audio:visible:eq(${targetIndex + 1})`))
      refresh()
      e.preventDefault()
      return false
    })

    // 選択された作品の削除ボタンを押した時にチェックボックスも解除
    $(document).on('click', '.js-selected-audio-remove', (e) => {
      const audioId = $(e.target).data('audio-id')
      $(`#js-audio-${audioId}`).prop('checked', false).change()
    })

    // 各種状態を更新
    const refresh = () => {
      $selectedAudioItemsWrapper.sortable('refresh')
      refreshXorder()
      refreshSortButton()
      refreshSelectedAudiosWrapper()
    }

    // xorderを更新
    const refreshXorder = () => {
      $('.js-original-album-entry-selected-audio:visible').each((index, selectedAudioItem) => {
        $(selectedAudioItem).find('.js-selected-audio-xorder').html(index + 1)
        $(selectedAudioItem).find('.js-selected-audio-xorder-field').val(index)
      })
    }

    // 並べ替えボタンの状態を更新
    const refreshSortButton = () => {
      const disabledClass = 'original-album-entry-audios-selected-audios-sort-disabled'
      $('[class*=js-selected-audio-change-sort-]').removeClass(disabledClass)
      $('.js-selected-audio-change-sort-up:visible:first').addClass(disabledClass)
      $('.js-selected-audio-change-sort-down:visible:last').addClass(disabledClass)
    }

    // 選択された作品の枠の表示/非表示を制御
    const refreshSelectedAudiosWrapper = (forceShow = false) => {
      const $selectedAudiosWrapper = $('.js-original-album-entry-selected-audios-wrapper')
      const $selectedAudiosDummy = $('.js-original-album-entry-selected-audios-dummy')
      const $selectedAudios = $('.js-original-album-entry-selected-audio:visible')
      const $submitButton = $form.find('button[type=submit]')
      if (forceShow || $selectedAudios.length > 0) {
        $selectedAudiosWrapper.show()
        $selectedAudiosDummy.hide()
        $submitButton.prop('disabled', false)
      } else {
        $selectedAudiosWrapper.hide()
        $selectedAudiosDummy.show()
        $submitButton.prop('disabled', true)
      }
    }

    // 初期化
    $audioCheckbox.each((_, checkbox) => toggleCheckbox($(checkbox)))
    $selectedAudioItemsWrapper.sortable({ axis: 'y', update: refresh, distance: 10 })
    refreshSortButton()
    refreshSelectedAudiosWrapper()
  }
})
