import React from 'react'
import {menuIconClassName} from './utils'

const HeaderMenu = (props) => {
  const { menu, show, inPage } = props
  return (
    <nav id="headerMenu" className={`collapse ${show ? 'show' : ''}`}>
      <div className={`${inPage ? '' : 'container-fluid py-4'}`}>
        {menu.filter(({ permitted }) => permitted === 'true').map(({ title, links }) => (
          <div className="card mb-4" key={title}>
            <div className="card-header">
              <span className="text-dark">
              <i className={`${menuIconClassName(title)} fa-lg text-body-secondary me-1`} />
                {title}
              </span>
            </div>
            <ul className="card-body p-0 nav flex-column">
              {links.filter(({ permitted }) => permitted === 'true').map(({ name, url }) => (
                <li className="nav-item" key={name}>
                  <a href={url} className={`nav-link ${url === location.pathname ? 'active' : ''}`}>
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      {!inPage && <hr />}
    </nav>
  )
}

export default HeaderMenu
