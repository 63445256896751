document.addEventListener('DOMContentLoaded', () => {
  const ultimateBgmBundleElement = document.querySelector('[data-controller="ultimate_bgm_bundle"]')

  if (ultimateBgmBundleElement) {
    // ======== 背景画像に視差効果を付ける
    const updateBgParallaxPosition = () => {
      ultimateBgmBundleElement.style.setProperty('--bg-parallax-position', `${-window.scrollY}px`)
    }
    document.addEventListener('scroll', updateBgParallaxPosition)
    document.addEventListener('resize', updateBgParallaxPosition)
    updateBgParallaxPosition()

    // ======== 残り時間カウンターの制御
    const dayElement = ultimateBgmBundleElement.querySelector('[data-counter="day"]')
    const timeElement = ultimateBgmBundleElement.querySelector('[data-counter="time"]')
    const targetDate = new Date('2024/4/1 00:00:00')

    const countDown = () => {
      const now = new Date()
      const diffTime = targetDate.getTime() > now.getTime() ? targetDate.getTime() - now.getTime() : 0

      const day    = Math.floor(diffTime / 1000 / 60 / 60 / 24)
      const hour   = Math.floor(diffTime / 1000 / 60 / 60) % 24
      const minute = Math.floor(diffTime / 1000 / 60) % 60
      const second = Math.floor(diffTime / 1000) % 60

      dayElement.innerHTML = String(day).padStart(2, '0')
      timeElement.innerHTML = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:${String(second).padStart(2, '0')}`
    }

    countDown()
    setInterval(countDown, 1000)
  }
})
