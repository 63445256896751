document.addEventListener('DOMContentLoaded', () => {
  const birthday_inputs = document.querySelectorAll('.js-certify-information-birthday')

  if (birthday_inputs.length > 0) {
    setMessageDisplay()
    birthday_inputs.forEach((birthday_input) => {
      birthday_input.addEventListener('change', (e) => {
        setMessageDisplay()
      })
    })
  }
})

const ageCalculation = ( birthDate , nowDate ) => {
  const birthNumber = birthDate.getFullYear() * 10000
    + (birthDate.getMonth() + 1 ) * 100
    + birthDate.getDate();
  const nowNumber = nowDate.getFullYear() * 10000
    + (nowDate.getMonth() + 1 ) * 100
    + nowDate.getDate();

  return Math.floor( (nowNumber - birthNumber) / 10000 );
}

const setMessageDisplay = () => {
  const date = new Date(document.getElementById('certify_information_birthday_1i').selectedOptions[0].value,
    document.getElementById('certify_information_birthday_2i').selectedOptions[0].value - 1,
    document.getElementById('certify_information_birthday_3i').selectedOptions[0].value);

  if (ageCalculation(date, new Date()) >= 18) {
    document.querySelector('.js-certify-information-submit').disabled = false
    document.querySelector('.js-certify-information-birthday-message').classList.add('d-none')
  } else {
    document.querySelector('.js-certify-information-submit').disabled = true
    document.querySelector('.js-certify-information-birthday-message').classList.remove('d-none')
  }
}
