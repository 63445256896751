document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js-recaptcha-tag input[id^="g-recaptcha-response-data-"]').forEach((recaptchaResponseData) => {
    const actionName = recaptchaResponseData.id.replace('g-recaptcha-response-data-', '')
    const executeMethodName = `executeRecaptchaFor${actionName.charAt(0).toUpperCase() + actionName.slice(1).toLowerCase()}Async`
    if (typeof window[executeMethodName] === 'function') {
      const form = recaptchaResponseData.closest('form')
      if (form && form.id !== 'payment-form') {
        form.addEventListener('submit', async (e) => {
          e.preventDefault()
          recaptchaResponseData.value = await window[executeMethodName]()
          form.submit()
        })
      }
    }
  })
})
