document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-osamu-lp')) {
    const agreementCheckBox = document.querySelector('.js-osamu-lp-agreement')
    const downloadSurvey = document.querySelector('.js-osamu-lp-download-survey-collapse')
    const downloadSurveyField = document.querySelector('.js-osamu-lp-download-survey-field')
    const submitBtn = document.querySelector('.js-osamu-lp-submit')

    if (agreementCheckBox && downloadSurveyField) {
      const enableSubmitBtn = () => {
        submitBtn.removeAttribute('disabled')
        submitBtn.classList.add('btn-osamu-lp-red')
        submitBtn.classList.remove('btn-secondary')
      }
      const disableSubmitBtn = () => {
        submitBtn.setAttribute('disabled', 'disabled')
        submitBtn.classList.remove('btn-osamu-lp-red')
        submitBtn.classList.add('btn-secondary')
      }

      agreementCheckBox.onchange = (e) => {
        if (e.target.checked) {
          $(downloadSurvey).collapse('show')
          if (downloadSurveyField.value) {
            enableSubmitBtn()
          }
        } else {
          $(downloadSurvey).collapse('hide')
          disableSubmitBtn()
        }
      }

      const handleSurveyInput = (e) => {
        if (e.target.value && agreementCheckBox.checked) {
          enableSubmitBtn()
        } else {
          disableSubmitBtn()
        }
      }
      downloadSurveyField.oninput = (e) => handleSurveyInput(e)
      downloadSurveyField.onchange = (e) => handleSurveyInput(e)
      downloadSurveyField.onkeydown = (e) => handleSurveyInput(e)
      downloadSurveyField.onkeyup = (e) => handleSurveyInput(e)
    }
  }
})
