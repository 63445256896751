$(() => {
  const $inputCounterContainer = $('.js-input-counter_container')

  if ($inputCounterContainer.is('*')) {
    $inputCounterContainer.each((index, container) => {
      const $container = $(container)
      const $targetField = $container.find('.js-input-counter_target')
      const $counter = $container.find('.js-input-counter_counter')
      const $recommendWarning = $container.find('.js-input-counter-recommend-warning')

      const maxlength = $targetField.attr('maxlength')
      const recommendLength = $targetField.data('recommend-length')

      const refreshCounter = () => {
        const count = $targetField.val() ? $targetField.val().replace(/\\n|\r\n|\n\r|\r|\n/g, '\r\n').length : 0
        $counter.text(`${count} / ${maxlength}`)

        if (maxlength < count) {
          $counter.addClass('text-danger')
        } else {
          $counter.removeClass('text-danger')
        }
        if (recommendLength && recommendLength < count) {
          $recommendWarning.removeClass('d-none')
        } else {
          $recommendWarning.addClass('d-none')
        }
      }

      $targetField.on('change keyup keydown keypress paste cut', refreshCounter)
      refreshCounter()
    })
  }
})
