document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-account-favorite-button')) {
    // 領域外をクリックするとポップオーバーを閉じる
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.popover')) {
        document.querySelectorAll('.account-favorite-button').forEach((button) => $(button).popover('dispose'))
      }
    })
  }
})
