function smoothScroll(target) {
  $('a[href^=\"' + target + '\"]').click(function(e) {
    var speed = 800;
    var href= $(e.currentTarget).attr('href');
    var position = $(href).offset().top;
    $('html, body').animate({ scrollTop: position }, speed, 'swing');
    return false;
  });
}

window.smoothScroll = smoothScroll;