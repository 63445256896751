import $ from 'jquery'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

document.addEventListener('DOMContentLoaded', () => {
  // 事例紹介
  const interviewsSliders = document.querySelectorAll('.interviews-slider:not(.interviews-slider-in-top)')
  const interviewsSlidersInTop = document.querySelectorAll('.interviews-slider-in-top')

  if (interviewsSliders.length > 0) {
    interviewsSliders.forEach((interviewsSlider) => {
      $(interviewsSlider).slick({
        respondTo: 'slider',
        infinite: true,
        autoplay: true,
        speed: 300,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        responsive: [{
          breakpoint: 720,
          settings: {
            slidesToShow: 2
          }
        }, {
          breakpoint: 540,
          settings: {
            slidesToShow: 1
          }
        }]
      })
    })
  }

  if (interviewsSlidersInTop.length > 0) {
    interviewsSlidersInTop.forEach((interviewsSliderInTop) => {
      $(interviewsSliderInTop).slick({
        respondTo: 'slider',
        infinite: true,
        autoplay: true,
        speed: 300,
        autoplaySpeed: 4000,
        slidesToShow: 1
      })
    })
  }


  // Sounds of JAPAN
  const sojEyeCatchSliders = document.querySelectorAll('.js-soj-eye-catch-slider')
  if (sojEyeCatchSliders.length > 0) {
    sojEyeCatchSliders.forEach((sojEyeCatchSlider) => {
      $(sojEyeCatchSlider).slick({
        dots: false,
        arrows: false,
        draggable: false,
        infinite: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1000,
        cssEase: 'ease'
      })
    })
  }
})
