import React from 'react'
import classNames from 'classnames'
import Highlighter from 'react-highlight-words'

const TargetAudiosTable = ({ targetAudios, selectedAudioId, searchWord, searchIndex, handleSelectAudio, popupRef }) => (
  <div className="variation-target-audios-list">
    <table className="variation-target-audios-table">
      <tbody ref={popupRef}>
      {targetAudios.length ? (
        targetAudios.map(({ id, name, summary }, index) => (
          <tr
            key={id}
            onClick={() => handleSelectAudio(id)}
            className={classNames({ focused: index === searchIndex, selected: id === selectedAudioId })}
          >
            <th>No. {id}</th>
            <th>
              {<Highlighter searchWords={[searchWord]} textToHighlight={name} />}
            </th>
            <td className="d-none d-sm-table-cell">
              {<Highlighter searchWords={[searchWord]} textToHighlight={summary} />}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td className="variation-target-audios-table-no-audio">選択できる作品がありません</td>
        </tr>
      )}
      </tbody>
    </table>
  </div>
)

export default TargetAudiosTable
