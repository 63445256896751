/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(function() {
  if ($('.js-article-template-select').is('*')) {
    $('body').on('change', '.js-article-template-select', function() {
      const val = $('.js-article-template-select option:selected').val();
      if (val === '') {
        return $('.js-article-body').val('');
      } else {
        const text = $('.js-article-template-' + val).text();
        return $('.js-article-body').val(text);
      }
    });
  }

  if ($('.js-preview-true').is('*')) {
    window.open($('.js-preview-true a').attr('href'));
  }

  if ($('.js-audio-category-filter').is('*')) {
    $('body').on('keyup', '.js-audio-category-filter', function(e) {
      const key = $('.js-audio-category-filter').val();
      if (key.length !== 0) {
        return $(this).parent().next('.form-check-buttons').find('.js-audio-category').each(function(i, elem) {
          const target_name = $(elem).find('.form-check').data('category-name');
          if (target_name.indexOf(key) === -1) {
            return $(elem).hide();
          } else {
            return $(elem).show();
          }
        });
      } else {
        return $('.js-audio-category').show();
      }
    });
  }

  if ($('.js-article-description').is('*')) {
    return $('body').on('keyup', '.js-article-description', function(e) {
      const count = e.target.value.length;
      return $('.js-description-count').html(count);
    });
  }
});
