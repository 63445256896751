document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-single-purchase-bill')) {
    checkBillSettingChoose()

    document.querySelectorAll('.js-bill-setting-choose').forEach((element) => {
      element.addEventListener('change', checkBillSettingChoose)
    })

    function checkBillSettingChoose() {
      const purchaseConfirmationModalInvoicePostNote = document.querySelector('.js-purchase-confirmation-modal-invoice-post-note')
      const billSettingInvoicePostRadio  = document.querySelector('.js-bill-setting-invoice-post-radio')

      if (billSettingInvoicePostRadio.checked) {
        purchaseConfirmationModalInvoicePostNote.classList.remove('d-none')
      } else {
        purchaseConfirmationModalInvoicePostNote.classList.add('d-none')
      }
    }
  }
})
