$(function() {
  if ($('.js-creators-pack').is('*')) {
    var $jsCartBtnTop = $('.js-cart-btn-top');
    var $jsCartBtnBottom = $('.js-cart-btn-bottom');
    var $jsCartBtnFloat = $('.js-cart-btn-float');

    var windowHeight = $(window).height();
    var startPosition = $jsCartBtnTop.offset().top + $jsCartBtnTop.height();
    var endPosition = $jsCartBtnBottom.offset().top + $jsCartBtnFloat.outerHeight();

    $jsCartBtnBottom.css({ opacity: 0 });
    $jsCartBtnFloat.hide();

    $(window).scroll(function() {
      var scrollPosition = $(window).scrollTop();

      if (scrollPosition + windowHeight > endPosition) {
        // 2つ目の「カートに入れる」ボタンよりも下の位置の場合
        $jsCartBtnFloat.stop();
        $jsCartBtnFloat.hide();
        $jsCartBtnBottom.css({ opacity: 1 });
      } else if (scrollPosition > startPosition) {
        // 1つ目の「カートに入れる」ボタンが見えない位置の場合
        if ($jsCartBtnBottom.css('opacity') === '1') {
          $jsCartBtnFloat.show();
        } else {
          $jsCartBtnFloat.slideDown('slow');
        }
        $jsCartBtnBottom.css({ opacity: 0 });
      } else {
        // 1つ目の「カートに入れる」ボタンが見える位置の場合
        $jsCartBtnFloat.slideUp('slow');
        $jsCartBtnBottom.css({ opacity: 0 });
      }
    });

    $(window).resize(function() {
      windowHeight = $(window).height();
      startPosition = $jsCartBtnTop.offset().top + $jsCartBtnTop.height();
      endPosition = $jsCartBtnBottom.offset().top + $jsCartBtnFloat.outerHeight();
    });
  }

  if ($('.fuurinkazan').is('*')) {
    $(function() {
      smoothScroll('#package-');

      // CSSアニメーションのスクロール制御
      var $eyeCatch = $('.fuurinkazan-eye-catch');
      var windowHeight = $(window).height();
      var offset = 120;

      var scrollCheck = function() {
        var scroll = $(window).scrollTop();
        $eyeCatch.not('.animate').each(function() {
          var position = $(this).offset().top;
          if (scroll > position + offset - windowHeight) {
            $(this).addClass('animate');
          }
        });
      };
      $(window).scroll(scrollCheck);
      $('body').on('touchmove', scrollCheck);
      scrollCheck();

      // 波紋エフェクト
      var $fire = $('.fuurinkazan-eye-catch-fire');
      if ($fire.is('*')) {
        var randomRipple = function() {
          var x = Math.random() * $fire.outerWidth();
          var y = Math.random() * $fire.outerHeight();
          var dropRadius = 30;
          var strength = Math.random() * .01;
          $fire.ripples('drop', x, y, dropRadius, strength);
        }

        $fire.ripples({ resolution: 512, perturbance: .02, interactive: false });
        for (var i = 0; i < 40; i++) randomRipple() // 初期である程度揺らしておく
        setInterval(randomRipple, 40);
      }
    });
  }
});
