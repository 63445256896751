import React from 'react'

const SearchField = ({ audios, targetAudios, searchIndex, searchWord, setSearchIndex, setSearchWord, handleSelectAudio, searchFieldRef }) => {
  const handleSetSearchWord = (e) => {
    setSearchWord(e.target.value)
    setSearchIndex(-1)
  }
  const handleKeyDown = (e) => {
    switch (e.keyCode) {
    case 13: // Enter
      if (searchIndex >= 0) {
        handleSelectAudio(targetAudios[searchIndex].id)
      }
      e.preventDefault()
      return false
    case 38: // 上キー
      setSearchIndex(searchIndex <= 0 ? targetAudios.length - 1 : searchIndex - 1)
      e.preventDefault()
      return false
    case 40: // 下キー
      setSearchIndex(searchIndex >= targetAudios.length - 1 ? 0 : searchIndex + 1)
      e.preventDefault()
      return false
    }
  }
  return (
    <span className="d-flex justify-content-between align-items-center">
      <span className="flex-grow-1">
        <i className="fass fa-search text-body-secondary me-1" />
        <input
          type="text"
          onChange={handleSetSearchWord}
          onKeyDown={  (e) => handleKeyDown(e) }
          placeholder="検索キーワード"
          ref={searchFieldRef}
        />
      </span>
      <span className="h5 mb-0">
        <span className="badge bg-secondary fw-bold">
          {searchWord.length ? (
            <span><strong>{targetAudios.length.toLocaleString()}</strong> / {audios.length.toLocaleString()} 件</span>
          ) : (
            <span><strong>{audios.length.toLocaleString()}</strong> 件</span>
          )}
        </span>
      </span>
    </span>
  )
}

export default SearchField
