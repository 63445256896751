// セレクトボックスで作品種別を切り替えると検索フォームの送信先が書き換わる

document.addEventListener('DOMContentLoaded', () => {
  const multiSearchFormBlock = document.querySelector('#js-multi-search-form-block')
  const multiSearchForm = document.querySelector('#js-multi-search-form')
  const multiSearchFormCategory = document.querySelector('#js-multi-search-form-category')

  if (multiSearchFormBlock && multiSearchForm && multiSearchFormCategory) {
    const categoryUrlMap = {
      '0': '/bgm',
      '1': '/se',
      '2': '/voice',
      '3': '/vocal'
    }
    const replaceActionByCategory = () => {
      multiSearchForm.action = categoryUrlMap[multiSearchFormCategory.value]
    }

    replaceActionByCategory()
    multiSearchFormCategory.addEventListener('change', replaceActionByCategory)
  }
})
