var informationTooltipLegacyMode = false;

function createInformationTooltip(event, tooltip)
{
  var target = $(event.target);
  var informationTooltip = $("#informationTooltip");
  var left = offsetGetter(target, event).left;
  var contentInner = $(".content-inner");

  informationTooltip.html(tooltip);
  informationTooltip.css("top", (offsetGetter(target, event).top - informationTooltip.outerHeight()) + "px");

  if(offsetGetter(contentInner, event).left + contentInner.width() < left + informationTooltip.width() && !informationTooltipLegacyMode)
  {
    informationTooltip.addClass("information-tooltip-right-arrow");
    informationTooltip.css("right", (offsetGetter(contentInner, event).left * 2 + contentInner.width() - offsetGetter(target, event).left - target.width()) + "px");
    informationTooltip.css("left", "");
  }
  else
  {
    informationTooltip.removeClass("information-tooltip-right-arrow");
    informationTooltip.css("left", offsetGetter(target, event).left + "px");
    informationTooltip.css("right", "");
  }

  informationTooltip.fadeIn();
  //informationTooltip.show();
}

function offsetGetter(target, event)
{
  var ret = null;

  if(!(ret = target.offset()))
  {
    ret = {left: event.clientX, top: event.clientY};
    informationTooltipLegacyMode = true;
  }

  return ret;
}

function destroyInformationTooltip()
{
  var informationTooltip = $("#informationTooltip");
  //informationTooltip.hide();
  informationTooltip.fadeOut("fast");
  //informationTooltip.text("");
}

window.createInformationTooltip = createInformationTooltip;
window.destroyInformationTooltip = destroyInformationTooltip;
