$(() => {
  const $copyright_request_on = $('.js-copyright-request-on')
  const $copyright_request_off = $('.js-copyright-request-off')
  const $copyright_required_true = $('.js-copyright-required-true')
  const $copyright_required_false = $('.js-copyright-required-false')
  const $categories = $('.js-categories')

  const toggleButtons = (checked) => {
    if (checked) {
      $copyright_required_true.removeClass('d-none')
      $copyright_required_false.addClass('d-none')
      $categories.removeClass('d-none')
    } else {
      $copyright_required_true.addClass('d-none')
      $copyright_required_false.removeClass('d-none')
      $categories.removeClass('d-none')
    }
  }

  if ($copyright_request_on.is('*')) {
    $(document).on('click', '.js-copyright-request-on', () => toggleButtons(true))
  }

  if ($copyright_request_off.is('*')) {
    $(document).on('click', '.js-copyright-request-off', () => toggleButtons(false))
  }

  // 初期化
  if ($copyright_request_on.prop('checked')) toggleButtons(true)
  if ($copyright_request_off.prop('checked')) toggleButtons(false)
})
