/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

// 一応変換はしたが、現在は ASA の管理画面に新規登録の機能が無いため、どこからも呼ばれない
$(function() {
  // 音源登録画面でファイルアップロードしたら自動でタイトルハマるようにする奴
  const audio_file = $("#audio_file_field");
  return audio_file.change(function() {
    const audio_name = $("#audio_name");
    if (!audio_name.val()) {
      const file = $(this).prop('files')[0];
      audio_name.val(file.name.replace(/.wav/i, ""));
      return audio_name.change();
    }
  });
});
