/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(function() {
  if ($('#js-create_account_form').is('*')) {
    disableOtherForm();

    return $('.js-regist-reason-other').on('change', () => disableOtherForm());
  }
});

var disableOtherForm = function() {
  if ($('.js-regist-reason-other').prop('checked') === true) {
    return $('.js-regist-reason-other-input').prop('disabled', false);
  } else {
    return $('.js-regist-reason-other-input').prop('disabled', true);
  }
};
