/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
window.addCartDirect = function(audioId, largeBtnFlag){
  if (largeBtnFlag == null) { largeBtnFlag = false; }
  if (largeBtnFlag) {
    return AddCartCommon.addCart(audioId, changeSelectedLargeBtn);
  } else {
    return AddCartCommon.addCart(audioId, changeSelectedBtn);
  }
};

var changeSelectedBtn = function(audioId){
  const cartButton = document.getElementById('audio-cart-button-' + audioId);
  cartButton.classList.remove('player-audio-btn-cart');
  return cartButton.classList.add('player-audio-btn-cart-active');
};

var changeSelectedLargeBtn = function(audioId){
  const cartButtons = document.getElementsByClassName('js-audio-cart-button-' + audioId);
  return [].forEach.call(cartButtons, function(cartButton) {
    cartButton.classList.remove('btn-outline-orange-lg');
    return cartButton.classList.add('btn-orange-lg');
  });
};
