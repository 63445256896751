document.addEventListener('DOMContentLoaded', () => {
  const youtubeUrlField = document.querySelector('.js-sponsored-tips-videos-youtube-url-input')
  const youtubeUrlForm = document.querySelector('.js-sponsored-tips-videos-youtube-url-form')

  if (youtubeUrlField) {
    youtubeUrlField.oninput = () => youtubeUrlForm.querySelector('input[type="submit"]').click()
  }
  const regetUploadedDayField = document.querySelector('.js-sponsored-tips-videos-reget-uploaded-day-button')

  if (regetUploadedDayField) {
    regetUploadedDayField.onclick = () => {
      let hiddenElement = document.createElement('input');
      hiddenElement.type = 'hidden'
      hiddenElement.name = 'only_uploaded_day'
      hiddenElement.value = 'true'
      youtubeUrlForm.appendChild(hiddenElement);
      youtubeUrlForm.querySelector('input[type="submit"]').click()
      youtubeUrlForm.removeChild(hiddenElement);
    }
  }
})
