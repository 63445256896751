/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

// admin_accounting/csv_year_downloads でも使用
window.formSubmitWithActionRewrite = function(url) {
  $('form').attr('action', url);
  $('form').submit();
};
