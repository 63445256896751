/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// INFO: 管理画面(admin/accounts/:account_id/billing_email_settings)でも使用しています。
$(function() {
  if ($('#bill_setting_another_email_setting_flag').is('*')) {
    return document.getElementById('bill_setting_another_email_setting_flag').onchange = toggleEmailEnable;
  }
});

var toggleEmailEnable = function() {
  const enable = !document.getElementById('bill_setting_another_email_setting_flag').checked;
  return document.getElementById('bill_setting_email').disabled = enable;
};